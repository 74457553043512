import {
  Paths,
  withLazy
} from 'ui/common';

const dImport = import('ui/insights');

export const InsightsRoutes = {
  insights: {
    component: withLazy(
      dImport,
      'Insights'
    ),
    path: Paths.Insights
  },
};
