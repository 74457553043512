import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  HContainerV,
  Text,
  Icon,
  FootprintTypeLogo,
} from 'ui/common/components';

import {
  Colors,
  Images,
  Shadows,
  Icons,
} from 'ui/common/constants';

const Container = styled(HContainerV)`
  justify-content: space-between;
  padding: 0.75rem;
  
  background-color: ${Colors.Black};
  box-shadow: ${Shadows.BasicShadow};
  border-bottom: 1px solid ${Colors.DarkBlack};
`;

const Image = styled.img`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  padding: 0 0.75rem;
`;

export const TopBar = props => {

  return (

    <Container>

      <Icon
        cursor={'pointer'}
        colorHover={Colors.LighterBlue}
        size={'1.4rem'}
        padding={'0 0 0 2rem'}
        icon={Icons.List}
        onClick={props.onMenuButtonClick}/>

      <FootprintTypeLogo
        height={'1.6rem'}/>

      <HContainerV>

        <Icon
          cursor={'pointer'}
          padding={'0 0.75rem 0 0'}
          colorHover={Colors.LighterBlue}
          size={'1rem'}
          icon={Icons.SizeFullscreen}
          onClick={props.onToggleFullScreen} />

        <Text>
          {props.username}
        </Text>

        <Image
          src={props.avatarSrc || Images.Icons.Avatar}
          alt={'avatar icon'}
          onClick={props.onAvatarClick} />

      </HContainerV>

    </Container>
  )
};

TopBar.displayName = 'TopBar';

TopBar.propTypes = {
  username: PropTypes.string,
  avatarSrc: PropTypes.string,
  onMenuButtonClick: PropTypes.func,
  onAvatarClick: PropTypes.func,
  onToggleFullScreen: PropTypes.func,
};

TopBar.defaultProps = {
  username: '',
  avatarSrc: '',
};
