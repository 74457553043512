import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors,
  Icons,
} from 'ui/common/constants';

import {
  HContainerV,
} from 'ui/common/components/Containers';

import {
  Text
} from 'ui/common/components/Type';

const Container = styled(HContainerV)``;

const Page = styled(Text)`
  
  display: flex;
  cursor: pointer;
  
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  width: 1.8rem;
  
  border: 1px solid ${props => (props.active && Colors.Blue) || Colors.Transparent};
  border-radius: 50vmin;
  
  color: ${props => (props.active && Colors.Blue) || Colors.White};
  
  transition: border 0.2s ease-out, color 0.2s ease-out;
  
  &:hover {
    
    color: ${Colors.Blue};
    border: 1px solid ${Colors.Blue};
  }
`;

const Control = styled.div`
  
  font-family: simple-line-icons;
  
  display: flex;
  cursor: pointer;
  
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  width: 1.8rem;
  
  border: 1px solid ${props => (props.active && Colors.Blue) || Colors.Grey};
  border-radius: 50vmin;

  background-color: ${props => (props.active && Colors.Blue) || Colors.Transparent};
  
  transition: border 0.2s ease-out, color 0.2s ease-out;
  
  &:before {
    content: "\\${props => props.icon}";
    color: ${props => (props.active && Colors.Black) || Colors.Grey};
  }
  
  &:hover::before,
  &:hover {
    color: ${props => (props.active && Colors.Blue) || Colors.Grey};
    background-color: ${Colors.Transparent};
  }
`;

export const Pager = props => {

  const createPageItems = (
    pages,
    page,
  ) => {

    if (!pages) {
      return [];
    }

    let pageItems = [];
    let offset = Math.max(page - 1, 1);
    offset = (offset + 3 >= pages && pages - 2) || offset;

    for (let i = 0; i < Math.min(pages, 3); i++) {

      pageItems.push(

        <Page
          key={`page-indicator-${i + offset}`}
          active={props.page === i + offset}>

          {i + offset}
        </Page>
      );
    }

    return pageItems;
  };

  const pageItems = createPageItems(
    props.pages,
    props.page,
  );

  return (

    <Container>

      <Control
        icon={Icons.ControlStart}
        active={props.page > 1}/>

      <Control
        icon={Icons.ArrowLeft}
        active={props.page > 1}/>

      {pageItems}

      <Control
        icon={Icons.ArrowRight}
        active={props.page < props.pages}/>

      <Control
        icon={Icons.ControlEnd}
        active={props.page < props.pages}/>

    </Container>
  );
};

Pager.displayName = 'Pager';

Pager.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

Pager.defaultProps = {
  pages: 0,
  page: 0,
};
