import {
  FetchApi
} from 'api/fetch';

export const MembersApi = {

  getSystemCredentials: async memberId => {

    if (!memberId) {
      throw Error('Unable to get member credentials for an invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/syscreds', {
        path: {
          memberId,
        }
      }
    );
  },

  updateUsername: async (
    memberId,
    username,
  ) => {

    if (!memberId) {
      throw Error('Unable to get member credentials for an invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/username', {
        method: 'PUT',
        body: {
          username,
        },
        path: {
          memberId
        },
      }
    );
  },

  getMemberSystemCredentials: async memberId => {

    if (!memberId) {
      throw Error('Unable to get member credentials for an invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/syscreds', {
        method: 'GET',
        path: {
          memberId,
        },
      }
    );

  },

  activateMember: async memberId => {

    if (!memberId) {
      throw Error('Unable to activate a member with an invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/syscreds', {
        method: 'POST',
        path: {
          memberId,
        },
      }
    );
  },

  deactivateMember: async (
    memberId,
    statusId
  )=> {

    if (!memberId) {
      throw Error('Unable to deactivate a member with an invalid memberId');
    }

    console.log(statusId);

    return FetchApi.send(
      'members/:memberId/syscreds', {
        method: 'DELETE',
        path: {
          memberId,
        },
        body: {
          'statusid': statusId
        }
      }
    );
  },

  changeEmailAddress: async (
    memberId,
    email
  ) => {

    if (!memberId) {
      throw Error('Unable to get member credentials for an invalid memberId');
    }

    if(!email) {
      throw Error('No email address value to update');
    }

    return FetchApi.send(
      'members/:memberId/email', {
        method: 'PUT',
        body: {
          email,
        },
        path: {
          memberId,
        },
      }
    );
  },

  verifyChangeEmailAddress: async (
    memberId,
    code
  ) => {

    if (!memberId) {
      throw Error('No memberId - unprocessible');
    }

    if(!code) {
      throw Error('No code - unprocessible');
    }

    return FetchApi.send(
      'members/:memberId/email?verify=true', {
        method: 'PUT',
        body: {
          code
        },
        path: {
          memberId
        },
      }
    );
  },

  getMemberUiProfile: async memberId => {

    if (!memberId) {
      throw Error('Unable to get member Ui Profile for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/uiprofile', {
        method: 'GET',
        path: {
          memberId
        },
      }
    );
  },

  getMemberInformation: async memberId => {

    if (!memberId) {
      throw Error('Unable to get member information for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId', {
        method: 'GET',
        path: {
          memberId
        },
      }
    );
  },

  getMemberCurrentInsurance: async (
    memberId
  ) => {

    if (!memberId) {
      throw Error('Unable to get member medical current information for invalid memberId');
    }
  
    return FetchApi.send(
      'members/:memberId/medical/current', {
        method: 'GET',
        path: {
          memberId
        },
      }
    );
  },

  getMemberInjuryHistory: async (
    memberId
  ) => {

    if (!memberId) {
      throw Error('Unable to get member injury history for invalid memberId');
    }
  
    return FetchApi.send(
      'members/:memberId/injuries', {
        method: 'GET',
        path: {
          memberId
        },
      }
    );
  },

  getMemberGeneralSettingsInformation: async (
    memberId
  ) => {

    if (!memberId) {
      throw Error('Unable to get member general settings information for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/settings', {
        method: 'GET',
        path: {
          memberId
        },
      }
    );

  },

  updateMemberInformation: async(
    memberId, {
      DisplayName,
      PreferredName,
      FirstName,
      LastName,
      Weight,
      Height,
      StatusID,
      ReturnToPlay,
      ReturnToTrainingDate,
      GenderID,
      DateOfBirth,
      CityOfBirthID,
      CountryOfBirthID,
      DemographicID,
      CountryOfNationalityID,
      FullName,
      EthnicityID,
      PhysicallyDisabled,
      SportSafetyNumber,
      PositionID,
      PrimarySchoolName,
      SecondarySchoolName,
      TertiaryInstitutionName,
      NationalPlayerNo,
      SportsCodeID,
      RelationshipID,
      PlayerPrimaryPositionID,
      PlayerSecondaryPositionID,
      Club,
      OrganisationID,
  }) => {

    if (!memberId) {
      throw Error ('Unable to update member information for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId', {
        method: 'PUT',
        path: {
          memberId
        },
        body: {
          DisplayName,
          PreferredName,
          FirstName,
          LastName,
          Weight,
          Height,
          StatusID,
          ReturnToPlay,
          ReturnToTrainingDate,
          GenderID,
          DateOfBirth,
          CityOfBirthID,
          CountryOfBirthID,
          DemographicID,
          CountryOfNationalityID,
          FullName,
          EthnicityID,
          PhysicallyDisabled,
          SportSafetyNumber,
          PositionID,
          PrimarySchoolName,
          SecondarySchoolName,
          TertiaryInstitutionName,
          NationalPlayerNo,
          SportsCodeID,
          RelationshipID,
          PlayerPrimaryPositionID,
          PlayerSecondaryPositionID,
          Club,
          OrganisationID,
        },
      }
    );
  },

  getMemberIdentityInformation: async memberId => {

    if (!memberId) {
      throw Error ('Unable to get member identity information for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/identity', {
        method: 'GET',
        path: {
          memberId,
        },
      }
    );
  },

  updateMemberCurrentInsurance: async (
    memberId, {
      MedicalAidMainMember,
      MedicalAidName,
      MedicalAidNumber,
      MedicalNotes
    }) => {

    if (!memberId) {
      throw Error('Unable update member insurance for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/medical/current', {
        method: 'PUT',
        path: {
          memberId
        },
        body: {
          MedicalAidMainMember,
          MedicalAidName,
          MedicalAidNumber,
          MedicalNotes
        },
      }
    );
  },

  updateMemberIdentityInformation: async (
    memberId, {
      IdentityCardTypeID,
      IdentityCardIssueDate,
      IdentityCardNumber,
      IdentityCardCountryID,
      IdentityCardExpiryDate,
      DateOfBirth,
      DateDeceased,
      PassportCountryID,
      PassportIssueDate,
      PassportNumber,
      PassportExpiryDate,
      ResidencyStatusID,
    }) => {

    if (!memberId) {
      throw Error('Unable update member identity for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/identity', {
        method: 'PUT',
        path: {
          memberId
        },
        body: {
          IdentityCardTypeID,
          IdentityCardIssueDate,
          IdentityCardNumber,
          IdentityCardCountryID,
          IdentityCardExpiryDate,
          DateOfBirth,
          DateDeceased,
          PassportCountryID,
          PassportIssueDate,
          PassportNumber,
          PassportExpiryDate,
          ResidencyStatusID,
        },
      }
    );
  },

  getMembersPermissionTasks: async memberId => {

    if (!memberId) {
      throw new Error('Unable to get member permission tasks for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/permissions/tasks', {
        method: 'GET',
        path: {
          memberId
        },
      }
    );
  },

  updateMemberPermissionTask: async (
    memberId,
    memberTasks
  ) => {

    if (!memberId) {
      throw Error('Unable to update task for invalid memberId');
    }

    if (!Array.isArray(memberTasks) || !memberTasks.length) {
      throw Error('No tasks, provide a task');
    }

    return FetchApi.send(
      'members/:memberId/permissions/tasks', {
        method: 'POST',
        path: {
          memberId
        },
        body: memberTasks,
      }
    )
  },

  updateMemberPermissionGroups: async (
    memberId,
    memberGroups
  ) => {

    if (!memberId) {
      throw Error('Unable to update taks for invalid memberId');
    }

    if (!Array.isArray(memberGroups) || !memberGroups.length) {
      throw Error('No tasks, provide a task');
    }

    return FetchApi.send(
      'members/:memberId/permissions/rolegroups', {
        method: 'POST',
        path: {
          memberId
        },
        body: memberGroups,
      }
    )
  },

  updateMemberPermissionRoles: async (
    memberId,
    memberRoles
  ) => {

    if (!memberId) {
      throw Error('Unable to update taks for invalid memberId');
    }

    if (!Array.isArray(memberRoles) || !memberRoles.length) {
      throw Error('No tasks, provide a task');
    }

    return FetchApi.send(
      'members/:memberId/permissions/roles', {
        method: 'POST',
        path: {
          memberId
        },
        body: memberRoles,
      }
    )
  },

  getMembersPermissionRoles: async memberId => {

    if (!memberId) {
      throw Error('Unable to get member permissions for roles for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/permissions/roles', {
        method: 'GET',
        path: {
          memberId
        },
      }
    );
  },

  getMembersPermissionRoleGroups: async memberId => {

    if (!memberId) {
      throw Error('Unable to get member permissions role groups for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/permissions/rolegroups', {
        method: 'GET',
        path: {
          memberId
        },
      }
    );
  },

  getMemberContactDetails: async memberId => {

    if (!memberId) {
      throw Error('Unable to get member contact details for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/contactdetails',{
        method: 'GET',
        path: {
          memberId
        },
      }
    );
  },

  setMemberStatus: async (
    memberId, {
      StatusID
    }
  ) => {

    if (!memberId) {
      throw Error('Unable to update member status with an invalid memberId');
    }

    if (!StatusID) {
      throw Error('Unable to update member status with an invalid status');
    }

    return FetchApi.send(
      'members/:memberId/settings', {
        method: 'PUT',
        path: {
          memberId
        },
        body: {
          StatusID: StatusID.id
        },
      }
    );
  },

  updateMemberContactDetails: async (
    memberId, {
      CountryID,
      CountryName,
      CityID,
      ProvinceID,
      ProvinceName,
      SuburbID,
      SuburbName,
      CityName,
      Address,
      MobileNumber,
      PostalCode,
      NextOfKinInitials,
      NextOfKinFirstname,
      NextOfKinLastname,
      NextOfKinEmail,
      NextOfKinMobileNumber,
      NextOfKinRelationshipID,
      NextOfKinRelationshipName
  }) => {

    if (!memberId) {
      throw Error('Unable to update contact details for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/contactdetails', {
        method: 'PUT',
        path: {
          memberId
        },
        body: {
          CountryID,
          CountryName,
          CityID,
          ProvinceID,
          ProvinceName,
          SuburbID,
          SuburbName,
          CityName,
          Address,
          MobileNumber,
          PostalCode,
          NextOfKinInitials,
          NextOfKinFirstname,
          NextOfKinLastname,
          NextOfKinEmail,
          NextOfKinMobileNumber,
          NextOfKinRelationshipID,
          NextOfKinRelationshipName
        }
      }
    );
  },

  getMemberOrganisation: async memberId => {

    if (!memberId) {
      throw Error('Unable to get Member Organisation for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberId/organisation',{
        method: 'GET',
        path: {
          memberId
        },
      }
    );
  },

  addNewMember: async({
    FirstName,
    LastName,
    Height,
    Weight,
    StatusID,
    ReturnToPlay,
    ReturnToTrainingDate,
    Gender:{
      id: GenderID,
      name: GenderName,
    },
    DateOfBirth,
    CityOfBirthID,
    CountryOfBirthID,
    DemographicID,
    CountryOfNationalityID,
    FullName,
    EthnicityID,
    PhysicallyDisabled,
    SportSafetyNumber,
    PositionID,
    PrimarySchoolName,
    SecondarySchoolName,
    TertiaryInstitutionName,
    NationalPlayerNo,
    SportsCodeID,
    RelationshipID,
    PlayerPrimaryPositionID,
    PlayerSecondaryPositionID,
    Club,
    OrganisationID,
    Email,
    MobileNumber
  }) => {
    
    return FetchApi.send(
      'members/', {
        method: 'POST',
        body: {
          FirstName,
          LastName,
          Weight,
          Height,
          StatusID,
          ReturnToPlay,
          ReturnToTrainingDate,
          GenderID,
          GenderName,
          DateOfBirth,
          CityOfBirthID,
          CountryOfBirthID,
          DemographicID,
          CountryOfNationalityID,
          FullName,
          EthnicityID,
          PhysicallyDisabled,
          SportSafetyNumber,
          PositionID,
          PrimarySchoolName,
          SecondarySchoolName,
          TertiaryInstitutionName,
          NationalPlayerNo,
          SportsCodeID,
          RelationshipID,
          PlayerPrimaryPositionID,
          PlayerSecondaryPositionID,
          Club,
          OrganisationID,
          Email,
          MobileNumber,
        },
      }
    )
  },

  addMemberCredentials: async memberID => {

    if (!memberID) {
      throw new Error('Unable to add member credentials tasks for invalid memberId');
    }

    return FetchApi.send(
      'members/:memberID/syscreds', {
        method: 'POST',
        path: {
          memberID
        },
      }
    );
  },

  getContracts: async memberId => {

    if (!memberId) {
      throw new Error('Unable to get list of contracts for an invalid number');
    }

    return FetchApi.send(
      'members/:memberId/contracts', {
        method: 'GET',
        path: {
          memberId,
        },
      }
    );
  },
};
