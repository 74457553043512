import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors,
} from 'ui/common/constants';

const Container = styled.div`

  user-select: none;
  cursor: ${props => props.cursor};
  
  font-family: simple-line-icons;
  font-weight: 100;
 
  min-height: ${props => props.size};
  min-width: ${props => props.size};
  
  padding: ${props => props.padding};
  color: ${props => props.color || Colors.White};
  font-size: ${props => props.size};
  
  &:hover {
    color: ${props => props.colorHover}
  }
`;

export const Icon = props =>

  <Container
    {...props}>

    {props.icon}
  </Container>
;

Icon.propTypes = {
  cursor: PropTypes.string,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  padding: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string.isRequired,
}

Icon.defaultProps = {
  cursor: 'auto',
  color: Colors.White,
  colorHover: Colors.White,
  padding: '0',
  size: '2rem',
  icon: undefined,
}

Icon.displayName = 'Icon';
