import {
  FetchApi
} from 'api/fetch';

export const OrganisationsApi = {

  getOrganisations: async() => {

    return FetchApi.send(
      'organisations',{
        method: 'GET'
      },
    );
  },
}
