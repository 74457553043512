import
  React
  from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors,
} from 'ui/common/constants';

import {
  HFillContainerV,
} from 'ui/common/components/Containers';

import {
  Text
} from 'ui/common/components/Type';

import {
  Input,
  InputTypes,
} from 'ui/common/components/Input';
import {DropDown} from "../DropDown";

const Container = styled(HFillContainerV)`

  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
`;

const Cell = styled(Text)`
  user-select: none;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  font-weight: bold;
`;


export const Row = props => {

  const createRow = tuple => {

    if (!tuple) {
      return [];
    }

    let row = (props.actions && !props.readonly && [

      <Input
        key={`cell-select`}
        minWidth={'0'}
        width={'3rem'}
        type={InputTypes.CheckBox}
        checked={props.selected}
        onChange={props.onSelect}/>
    ]) || [];

    for (const [key, value] of Object.entries(tuple)) {

      if (props.columns && !props.columns.hasOwnProperty(key)) {
        continue;
      }

      if (props.columns && props.columns.hasOwnProperty(key) && props.columns[key].hasOwnProperty('visible') && !props.columns[key].visible) {
        continue;
      }

      row.push(

        <Cell
          key={`cell-${key}`}>

          {value}
        </Cell>
      );
    }

    props.actions && !props.readonly && row.push(

      <Cell
        key={'cell-actions'}>

        <DropDown
          id={'row-actions'}
          width={'5rem'}
          height={'0.8rem'}
          color={Colors.Grey}
          backgroundColor={Colors.DarkBlack}
          backgroundColorHover={Colors.Grey}
          border={`1px solid ${Colors.Grey}`}
          value={undefined}
          values={props.actions}
          placeholder={'ACTIONS'}
          onSelect={props.onAction}/>

      </Cell>
    );

    return row;
  };

  let row = createRow(props.tuple);

  return (

    <Container {...props}>
      {row}
    </Container>
  );
};

Row.displayName = 'Row';

Row.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  readonly: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  columns: PropTypes.object,
  tuple: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

Row.defaultProps = {
  color: Colors.White,
  backgroundColor: Colors.Transparent,
  readonly: false,
  actions: undefined,
  columns: undefined,
  tuple: undefined,
  selected: false,
  onSelect: undefined,
};
