import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  useHistory
} from 'react-router-dom';

import {
  useRecoilState,
} from 'recoil';

import
  PropTypes
from 'prop-types';

import {
  Footer,
  TopBar,
} from 'ui/common/scene/components';

import {
  VFillContainer,
  HFillContainer,
  Card,
  ToolBar,
  Spinner,
  useShowErrorToast
} from 'ui/common/components';

import {
  SideBarItems,
  FooterItems,
  ProfileMenuItems,
} from 'ui/common/scene/constants';

import {
  IdentityAtom,
  SceneAtom,
} from 'ui/atoms';

import {
  getPath
} from 'ui/navigation';

import {
  IdentityApi,
  MembersApi
} from 'api';

import {
  Colors,
} from 'ui/common/constants';

const ProfileMenuContainer = styled(Card)`
  cursor: pointer;
  position: absolute;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  
  top: 4rem;
  right: 1rem;
  height: 10rem;
  padding: 0.75rem;
`;

const Content = styled(VFillContainer)`
  flex: 9;
  padding: 0.75rem;
  height: 93vh;
  overflow: auto;
`;

export const Scene = props => {

  const history = useHistory(props.history);
  const showErrorToast = useShowErrorToast('Member Navigation Permissions');

  const [
    identity,
    setIdentity,
  ] = useRecoilState(IdentityAtom);

  const [
    state,
    setState,
  ] = useRecoilState(SceneAtom);

  React.useEffect(() => {

    if (!identity.memberId) {

      setState(prevState => ({
        ...prevState,
        allowedToolbarItems: {}
      }));

      return;
    }

    MembersApi
      .getMemberUiProfile(identity.memberId)
      .then(res => {

        if (!Array.isArray(res)) {

          setState(prevState => ({
            ...prevState,
            allowedToolbarItems: {}
          }));

          return;
        }

        let filteredMenu = {};

        for (const [key, value] of Object.entries(SideBarItems)) {

          if (!value) {
            continue;
          }

          if (value.items) {

            const allowedItems = {}

            for (const [itemKey, itemValue] of Object.entries(value.items)) {

              if (res.includes(itemValue.permissionKey)) {
                allowedItems[itemKey] = itemValue;
              }
            }

            if (Object.keys(allowedItems).length) {

              filteredMenu[key] = {
                ...value,
                items: allowedItems
              };
            }
            continue;
          }

          if (res.includes(value.permissionKey)) {
            filteredMenu[key] = value;
          }
        }

        setState(prevState => ({
          ...prevState,
          allowedToolbarItems: filteredMenu
        }));

      })
      .catch(e => {

        setState(prevState => ({
          ...prevState,
          allowedToolbarItems: {},
        }));

        showErrorToast(e)
      });
  }, [
    identity.memberId,
    showErrorToast,
    setState
  ]);

  const onMenuButtonClick = () => {

    setState(previousState => ({
      ...previousState,
      toolBarVisible: !previousState.toolBarVisible
    }));
  };

  const toggleFullScreen = () => {

    if (document.fullscreen) {
      document.exitFullscreen();
      return;
    }

    document.documentElement && document.documentElement.requestFullscreen();
  };

  const onSideBarClick = e => {

    if (!e || e.defaultPrevented || !e.target) {
      return;
    }

    e.preventDefault();

    const value = e.target.value;

    setState(previousState => ({
      ...previousState,
      activeItem: value,
      activeSubItem: undefined
    }));
  };

  const onSubItemClick = e => {

    if (!e || e.defaultPrevented || !e.target) {
      return;
    }

    e.preventDefault();

    let item = e.target.value;

    setState(previousState => ({
      ...previousState,
      activeSubItem: item
    }));

    item && item.route && item.route.length && history.push(item.route);
  };

  const onAvatarClick = () => {

    setState(previousState => ({
      ...previousState,
      dropDownVisible: !previousState.dropDownVisible
    }));
  };

  const onProfileMenuClick = e => {

    if (!e || e.defaultPrevented || !e.target) {
      return;
    }

    onAvatarClick();

    const value = e.target.value;

    switch (value.key) {

      case ProfileMenuItems.myProfile.key:

        history.push(getPath(
          value.route, {
            memberId: identity.memberId,
            section: 'home'
          }));

        return;

      case ProfileMenuItems.signOut.key:

        setState({
          toolBarVisible: true,
          allowedToolbarItems: undefined,
          activeItem: undefined,
          activeSubItem: undefined,
          dropDownVisible: false,
        });

        setIdentity(IdentityApi.logout());
        history.push(value.route);

        return;

      default:
        break;
    }

    onSubItemClick(value);
  };

  return (

    <VFillContainer>

      <TopBar
        username={identity.user}
        onMenuButtonClick={onMenuButtonClick}
        onToggleFullScreen={toggleFullScreen}
        onAvatarClick={onAvatarClick}/>

      { state.dropDownVisible &&

        <ProfileMenuContainer>

          <ToolBar
            item={state.activeItem}
            items={ProfileMenuItems}
            onClick={onProfileMenuClick}
            iconSize={'1.5rem'} />

        </ProfileMenuContainer>
      }

      <HFillContainer>

        <ToolBar
          iconSize={'1.5rem'}
          minWidth={'0'}
          width={(state.toolBarVisible && state.allowedToolbarItems && '6.8rem') || '0'}
          item={state.activeItem}
          items={state.allowedToolbarItems || {}}
          onClick={onSideBarClick}/>

        <ToolBar
          iconSize={'1rem'}
          itemDirection={'row'}
          itemIndicator={false}
          borderLeft={`1px solid ${Colors.DarkBlack}`}
          width={(state.toolBarVisible && state.activeItem && state.activeItem.items && '14rem') || '0'}
          item={state.activeSubItem}
          items={(state.activeItem && state.activeItem.items) || {}}
          onClick={onSubItemClick}/>

        <Content>
          {(!!props.busy && <Spinner/>) || props.children}
        </Content>

        <Footer
          items={FooterItems} />

      </HFillContainer>

    </VFillContainer>
  );
};

Scene.displayName = 'Scene';

Scene.propTypes = {
  busy: PropTypes.bool,
};

Scene.defaultProps = {
  busy: false,
};
