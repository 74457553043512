import
  React
from 'react';

import
  PropTypes
from 'prop-types';

import
  styled
from 'styled-components/macro';

import {
  Colors
} from 'ui/common/constants';

import {
  HContainer,
} from 'ui/common/components/Containers';

import {
  DropDown
} from 'ui/common/components/DropDown';

import {
  Input,
  InputTypes
} from 'ui/common/components/Input';

import {
  Spinner
} from 'ui/common/components/Spinner';

import {
  Text
} from 'ui/common/components/Type';

export const FieldTypes = {
  String: 'string',
  Integer: 'int',
  Date: 'date',
  Lookup: 'lookup',
  CheckBox: 'checkbox',
  LookupFilter: 'lookupfilter'
};

const Container = styled(HContainer)`
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0.75rem;
`;

const ItemContainer = styled.div`
  padding: 0.5em;
  min-width: ${props => props.width};
  flex-direction: ${props => props.flexDirection};
`;

const Label = styled(Text)`
  padding-right: 1em;
`;

export const KeyValueList = props => {

  let filterBouncing = undefined;

  const onFilter = (filter) => {

    if (typeof props.onFilter !== 'function') {
      return;
    }

    clearTimeout(filterBouncing);

    filterBouncing = setTimeout(
      () => props.onFilter(filter),
      props.filterEasing
    );
  };

  const getComponent = (
    key,
    value,
    edit = true,
  ) => {

    switch (value && typeof value.type === 'string' && value.type.toLowerCase()) {

      case FieldTypes.String:
        return (

          <Input
            id={key}
            name={key}
            key={key}
            color={props.valueColor}
            type={InputTypes.Text}
            disabled={!edit}
            value={value.value || ''}
            onChange={props.onChange}/>
        );

      case FieldTypes.Integer:
        return (

          <Input
            id={key}
            name={key}
            key={key}
            color={props.valueColor}
            type={InputTypes.Number}
            disabled={!edit}
            value={`${value.value}`}
            onChange={props.onChange}/>
        );

      case FieldTypes.Date:
        return (

          <Input
            id={key}
            name={key}
            key={key}
            color={props.valueColor}
            type={InputTypes.Date}
            disabled={!edit}
            value={value.value || ''}
            onChange={props.onChange}/>
        );

      case FieldTypes.Lookup:

        return (

          <DropDown
            id={key}
            name={key}
            key={key}
            margin={'0.5rem 0'}
            height={'1rem'}
            width={'auto'}
            color={props.valueColor}
            backgroundColor={Colors.Black}
            backgroundColorHover={Colors.Grey}
            backgroundColorDisabled={Colors.Transparent}
            border={`1px solid ${Colors.DarkerGrey}`}
            borderRadius={'0.2vmin'}
            disabled={!edit}
            values={value.lookupValues || []}
            value={value.value}
            onSelect={props.onChange} />
        );

      case FieldTypes.LookupFilter:

        return (

          <DropDown
            id={key}
            name={key}
            key={key}
            margin={'0.5rem 0'}
            height={'1rem'}
            width={'auto'}
            color={props.valueColor}
            backgroundColor={Colors.Black}
            backgroundColorHover={Colors.Grey}
            backgroundColorDisabled={Colors.Transparent}
            border={`1px solid ${Colors.DarkerGrey}`}
            borderRadius={'0.2vmin'}
            disabled={!edit}
            values={value.lookupValues || []}
            value={value.value}
            onSelect={props.onChange}
            onFilter={onFilter} />
        );

      case FieldTypes.CheckBox:

        return (

          <Input
            id={key}
            key={key}
            minWidth={'0'}
            width={'3rem'}
            type={InputTypes.CheckBox}
            checked={value.value}
            onChange={props.onChange} />
        );

      default:
        return (

          <Text>
            {value.value || props.defaultValue}
          </Text>
        );
    }
  }

  const getListItems = items => {

    if (!items && !!Object.keys(items).length) {
      return [];
    }

    let listItems = [];

    for (const [key, value] of Object.entries(items)) {

      if (!value.hasOwnProperty('label')) {
        continue;
      }

      let component = getComponent(
        key,
        value,
        props.edit && !value.readonly,
      );

      listItems.push(

        <ItemContainer
          key={`key-value-item-${key}`}
          width={props.width}
          flexDirection={props.flexDirection}>

          <Label
            color={props.labelColor}>

            {value.label}
          </Label>

          {component}
        </ItemContainer>
      );
    }

    return listItems;
  }

  const listItems = getListItems(props.items);

  return (

    <>

      {props.busy && <Spinner/>}

      { !props.busy &&

        <Container>
          {listItems}
        </Container>
      }

    </>
  );
}

KeyValueList.displayName = 'KeyValueList';

KeyValueList.propTypes = {
  items: PropTypes.object,
  defaultValue: PropTypes.string,
  flexDirection: PropTypes.string,
  width: PropTypes.string,
  labelColor: PropTypes.string,
  valueColor: PropTypes.string,
  edit: PropTypes.bool,
  busy: PropTypes.bool,
  onChange: PropTypes.func,
  filterEasing: PropTypes.number,
  onFilter: PropTypes.func,
};

KeyValueList.defaultProps = {
  items: undefined,
  defaultValue: '-',
  flexDirection: 'row',
  width: '32%',
  labelColor: Colors.Grey,
  valueColor: Colors.White,
  edit: false,
  busy: false,
  filterEasing: 500,
  onChange: undefined,
  onFilter: undefined
};
