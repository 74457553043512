import
  styled
from 'styled-components/macro';

import {
  Colors
} from 'ui/common/constants'; 

import {
  Text
} from 'ui/common/components/Type';

export const StatusPill = styled(Text)`
  padding: .55em .75em .6em;
  border-radius: 10rem;
  max-height: 1em;

  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: initial;

  font-size: 80%;
  width: 6em;
  color: ${props => props.color || Colors.White}
  font-weight: 700;
  border: 1px solid ${props => props.color || Colors.White};
  background-color: ${Colors.Black}
`;

StatusPill.displayName = 'StatusPill';
