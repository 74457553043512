import
  React
from 'react';

import
  PropTypes
from 'prop-types';

import
  styled
from 'styled-components/macro';

import {
  Colors,
} from 'ui/common/constants';

import {
  Card,
} from 'ui/common/components/Card';

import {
  Icon
} from 'ui/common/components/Icon';

import {
  HContainerV,
  HFillContainer,
} from 'ui/common/components/Containers';

import {
  Heading6,
} from 'ui/common/components/Type';

import {
  Icons
} from 'ui/common/constants';

const Container = styled(Card)`
  overflow: hidden;
  margin: ${props => props.margin};
  
  justify-content: space-between;
  flex-direction: column;
  transition: all 1s ease-out;
`;

const HeaderContainer = styled(HFillContainer)`
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.padding};
  height: 2rem;
  border-bottom: 1px solid ${props => (props.expanded && Colors.LightGrey) || Colors.Transparent};
`;

const ContentContainer = styled(HFillContainer)`
  max-height: ${props => (props.expanded && '30rem') || '0'};
  transition: max-height 0.3s ease-out;
`;

export const CollapsibleCard = props => {

  return (

    <Container
      margin={props.margin}>

      <HeaderContainer
        expanded={props.expanded}
        padding={props.padding}
        onClick={props.onClick}>

        <HContainerV>

          { !!props.icon &&

            <Icon
              size={'1.5rem'}
              padding={'0 0.75rem 0 0'}
              icon={props.icon}/>
          }

          <Heading6>
            {props.display}
          </Heading6>

        </HContainerV>

        <Icon
          cursor={'pointer'}
          size={'1rem'}
          padding={'0 0.75rem'}
          icon={(props.expanded && Icons.ArrowUp) || Icons.ArrowDown}/>

      </HeaderContainer>

      <ContentContainer
        expanded={props.expanded}>

        {props.expanded && props.children}
      </ContentContainer>

    </Container>
  );
}

CollapsibleCard.displayName = 'CollapsibleCard';

CollapsibleCard.propTypes = {
  margin: PropTypes.string,
  padding: PropTypes.string,
  expanded: PropTypes.bool,
  display: PropTypes.string,
  icon: PropTypes.string,
};

CollapsibleCard.defaultProps = {
  margin: '0.25rem 0',
  padding: '0.75rem',
  expanded: false,
  display: undefined,
  icon: undefined,
};
