import
React
  from 'react';

import
PropTypes
  from 'prop-types';

import
styled
  from 'styled-components/macro';

import {
  Card
} from 'ui/common/components/Card';

import {
  HContainer,
  HFillContainer,
} from 'ui/common/components/Containers';

import {
  Heading6,
} from 'ui/common/components/Type';

import {
  Icon
} from 'ui/common/components/Icon';

import {
  Icons
} from 'ui/common/constants';

import {
  Colors
} from 'ui/common/constants'

const ModalContainer = styled(Card)`
  display: ${props => props.isOpen ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const ModalMain = styled(Card)`
  position:absolute;
  flex-direction: column;
  width: 70%;
  padding: 2em;
  top:40%;
  left:50%;
  transform: translate(-50%,-50%);
`;

const HeaderContainer = styled(HFillContainer)`
  justify-content: space-between;
  margin-left: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid ${Colors.White};
`;

export const Modal = (props) => {

  return (
    <ModalContainer
      {...props}>

      <ModalMain>

        <HeaderContainer>

          <Heading6>
            {props.heading}
          </Heading6>

          <Icon 
            icon={Icons.Close}
            onClick={() => props.handleClose()}
            size={'1.5em'}/>

        </HeaderContainer>

        <HContainer>

        {props.children}

        </HContainer>
        
      </ModalMain>

    </ModalContainer>
  )

};

Modal.displayName = 'Modal';

Modal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  heading: PropTypes.string,
}
