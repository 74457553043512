import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  HContainerV,
  VFillContainer,
} from 'ui/common/components/Containers';

import {
  Button,
} from 'ui/common/components/Button';

import {
  ToolBar,
} from 'ui/common/components/ToolBar';

import {
  Spinner,
} from 'ui/common/components/Spinner';

const Container = styled(VFillContainer)`
  min-height: ${props => props.minHeight};
`;

const HeaderContainer = styled(HContainerV)`
  justify-content: space-between;
  padding: 0.75rem;
`;

export const SubSection = props =>

  <Container
    id={props.id}
    flex={(!!props.busy && '1') || 'auto'}
    minHeight={props.minHeight}>

    <HeaderContainer>

      { !!props.items &&

        <ToolBar
          itemDirection={'row'}
          flexDirection={'row'}
          item={props.item}
          items={props.items}
          onClick={(!props.busy && props.onSelect) || undefined}/>
      }

      { !!props.permission &&

        <HContainerV>

          { !props.edit &&

            <Button
              height={'0.75rem'}
              width={'1rem'}
              disabled={props.busy}
              text={'EDIT'}
              onClick={props.onToggleEdit}/>
          }

          { !!props.edit &&

            <>

              <Button
                margin={'0 0.4rem'}
                height={'0.75rem'}
                width={'1rem'}
                disabled={props.busy}
                text={'SAVE'}
                onClick={props.onSubmit}/>

              <Button
                height={'0.75rem'}
                width={'1rem'}
                disabled={props.busy}
                text={'CANCEL'}
                onClick={props.onToggleEdit}/>

            </>
          }

        </HContainerV>
      }

    </HeaderContainer>

    {(!props.busy && props.children) || <Spinner/>}
  </Container>
;

SubSection.displayName = 'SubSection';

SubSection.propTypes = {
  minHeight: PropTypes.string,
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }),
  items: PropTypes.objectOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    icon: PropTypes.string,
    display: PropTypes.string,
  })),
  busy: PropTypes.bool,
  permission: PropTypes.bool,
  edit: PropTypes.bool,
  onToggleEdit: PropTypes.func,
  onSelect: PropTypes.func,
  onSubmit: PropTypes.func,
};

SubSection.defaultProps = {
  minHeight: 'auto',
  item: undefined,
  items: undefined,
  busy: false,
  permission: false,
  edit: false,
  onToggleEdit: undefined,
  onSelect: undefined,
  onSubmit: undefined,
};
