import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors
} from 'ui/common/constants';

import {
  Icon
} from 'ui/common/components/Icon';

import {
  HFillContainerV
} from 'ui/common/components/Containers';

const Container = styled.div`

  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  
  ${props => (props.flexDirection === 'column' && 'align-items: center') || `justify-content: center`};
  
  flex-direction: ${props => props.flexDirection};
  padding: 0.75rem;
  
  &:hover > * {
    color: ${Colors.LighterBlue};
  }
`;

const ActiveIndicator = styled.div`

  position: absolute;
  
  border-radius: 1px;
  background-color: ${Colors.LighterBlue};
  
  left: ${props => props.left};
  bottom: ${props => props.bottom};
  height: ${props => props.height};
  width: ${props => props.width};
`;

const Display = styled(HFillContainerV)`
  font-size: ${props => props.fontSize};
  color: ${props => props.color};
`;

export const ToolBarItem = props =>

  <Container
    {...props}
    onClick={e => {
      e.target.value = props.item;
      typeof props.onClick === 'function' && props.onClick(e);
    }}>

    { !!props.item.icon &&

      <Icon
        cursor={'pointer'}
        color={(props.active && Colors.LighterBlue) || Colors.White}
        icon={props.item.icon}
        size={props.iconSize}
        padding={(props.flexDirection === 'column' && '0.4rem 0') || '0 0.6rem'}/>
    }

    <Display
      fontSize={props.fontSize}
      color={(props.active && Colors.LighterBlue) || Colors.White}>

      {props.item.display}
    </Display>

    { !!props.indicator && !!props.active &&

      <ActiveIndicator
        left={(props.flexDirection === 'column' && '0.1rem') || undefined}
        bottom={(props.flexDirection === 'row' && '0.3rem') || undefined}
        height={(props.flexDirection === 'column' && '90%') || '1px'}
        width={(props.flexDirection === 'column' && '3px') || '90%'}/>
    }

  </Container>
;

ToolBarItem.displayName = 'ToolBarItem';

ToolBarItem.propTypes = {
  flexDirection: PropTypes.string,
  item: PropTypes.object.isRequired,
  iconSize: PropTypes.string,
  fontSize: PropTypes.string,
  active: PropTypes.bool,
  indicator: PropTypes.bool,
}

ToolBarItem.defaultProps = {
  flexDirection: 'column',
  item: undefined,
  iconSize: '1.5rem',
  fontSize: 'inherit',
  active: false,
  indicator: true,
}
