import {
  Paths,
  withLazy
} from 'ui/common';

const dImport = import('ui/tournaments');

export const TournamentsRoutes = {
  tournaments: {
    component: withLazy(
      dImport,
      'Tournaments'
    ),
    path: Paths.Tournaments
  },
  tournamentsManage: {
    component: withLazy(
      dImport,
      'Tournaments'
    ),
    path: Paths.TournamentsManage
  },
  tournamentsNewTournament: {
    component: withLazy(
      dImport,
      'Tournaments'
    ),
    path: Paths.TournamentsNewTournament
  },
  tournamentsManageFixtures: {
    component: withLazy(
      dImport,
      'Tournaments'
    ),
    path: Paths.TournamentsManageFixtures
  },
  tournamentsInsightReports: {
    component: withLazy(
      dImport,
      'Tournaments'
    ),
    path: Paths.TournamentsInsightReports
  },
  tournamentsPivotReports: {
    component: withLazy(
      dImport,
      'Tournaments'
    ),
    path: Paths.TournamentsPivotReports
  },
};
