import
  React
from 'react';

import
  PropTypes
from 'prop-types';


import {
  CollapsibleCard
} from 'ui/common/components/CollapsibleCard';

import {
  VFillContainer
} from 'ui/common/components/Containers';

import { 
  IdentityAtom, 
  MemberAtom 
} from 'ui/atoms';

import { 
  useRecoilValue 
} from 'recoil';

export const Accordion = props => {

  const identity = useRecoilValue(IdentityAtom);
  const member = useRecoilValue(MemberAtom); 

  const [
    state,
    setState
  ] = React.useState({});

  const onItemClick = (key) => {

    setState(prevState => ({
        ...prevState,
        [key]: !prevState[key]
    }));
  };

  const createListItems = items => {  

    if (!items && !!Object.keys(items).length) {
      return [];
    }

    let listItems = [];

    for (const [key, value] of Object.entries(items)) {
      
      if (!value || !value.display) {
        continue;
      }

      if(value.onlyIdentityMemberCanView)
      {
        if(identity.memberId !== member.memberId)
        {
          continue;
        }
      }

      listItems.push(
        
        <CollapsibleCard
          key={`accordion-item-${key}`}
          display={value.display}
          icon={value.icon}
          expanded={state[key]}
          onClick={() => { onItemClick(key) }} >
            
          { value.content }

        </CollapsibleCard>
      );
    }

    return listItems;
  };

  const accordionItems = createListItems(props.items);

  return (
    <VFillContainer
      {...props}>

      {accordionItems}

    </VFillContainer>
  );
}

Accordion.displayName = 'Accordion';

Accordion.propTypes = {
  items: PropTypes.object.isRequired,
};
