import 
  React 
from 'react';

import
  PropTypes
from 'prop-types';

import
  styled
from 'styled-components/macro';

import {
  HFillContainer,
  VFillContainer,
} from 'ui/common/components/Containers';

import {
  Text
} from 'ui/common/components/Type';

import {
  Input,
  InputTypes
} from 'ui/common/components/Input';

const ListContainer = styled(HFillContainer)`
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0.5em 1em;
`;

const ItemContainer = styled(VFillContainer)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const BodyContainer = styled(HFillContainer)`
  justify-content: space-between;
  height: 4em;
  align-items: center;
  margin-left: 1em;
`;

export const CheckBoxList = (props) => {

  const {onCheckChange, ...rest} = props;

  const createItems = values => {

    if (!Array.isArray(values) || !values.length) {
      return [];
    }

    let items = [];

    for (let index = 0; index < values.length; index++) {

      const value = values[index];

      if (!value) {
        continue;
      }

      items.push(

        <ItemContainer
          key={`checkbox-list-${index}`}>

            <BodyContainer>
                       
            <Text>
                {value.DisplayName || value.TaskID || value.Displayname}
            </Text>
                
              <Input
                type={InputTypes.CheckBox}
                checked={value.Assigned}
                onChange={(e) => {
                  props.onCheckChange({
                    ...value,
                    Assigned: Number(e.target.checked)
                  })
                }}/>

           </BodyContainer>
          
        </ItemContainer>
      );
    }

    return items;
  }
  const items = createItems(props.values);

  return (
    <ListContainer
      {...rest}>
      
      {items}
      
    </ListContainer>
  );
}

CheckBoxList.displayName = 'CheckBoxListItem';

CheckBoxList.propTypes = {
  values: PropTypes.array,
  onCheckChange: PropTypes.func,
};

CheckBoxList.defaultProps = {};
