import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors,
  Shadows
} from 'ui/common/constants';

import {
  SearchBox
} from 'ui/common/components/SearchBox';

import {
  HFillContainer
} from 'ui/common/components';

const Container = styled.div`

  user-select: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${props => (props.disabled && 'auto') || 'pointer'};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  height: ${props => props.height};
  font-weight: 700;
  font-size: ${props => props.fontSize};
  transition: background-color 0.2s ease-out;
  
  white-space: nowrap;
  
  width: ${props => props.width};
  
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  
  color: ${props => (props.disabled && props.colorDisabled) || props.color};
  background-color: ${props => (props.disabled && props.backgroundColorDisabled) || props.backgroundColor};
  
  &:hover {
    color: ${props => (props.disabled && props.colorDisabled) || props.colorHover};
    background-color: ${props => (props.disabled && props.backgroundColorDisabled) || props.backgroundColorHover};
  }
`;

const ArrowContainer = styled.div`
  font-size: calc(${props => props.fontSize} / 1.8);
`;

const ItemsContainer = styled.div`

  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(${props => props.height} + ${props => (props.padding && props.padding.indexOf(' ') > -1 && props.padding.substring(0, props.padding.indexOf(' '))) || props.padding} * 2.2);
  left: 0;
  z-index: 1;
  
  overflow: auto;
  
  box-shadow: ${Shadows.BasicShadow};
  
  max-height: ${props => props.maxHeight};
  min-width: calc(${props => props.width} + ${props => (props.padding && props.padding.indexOf(' ') > -1 && props.padding.substring(0, props.padding.indexOf(' '))) || props.padding} * 2.2);
  
  border-radius: 1vmin;
  border: ${props => props.border};
  
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
`;

const Item = styled.div`
  
  display: flex;
  padding: ${props => props.padding};
  
  white-space: nowrap;
  font-size: ${props => props.fontSize};
  
  &:hover {
    color: ${props => props.colorHover};
    background-color: ${props => props.backgroundColorHover};
  }
`;

const ItemLabel = styled.div`
  
  display: flex;
  padding: ${props => props.padding};
  
  white-space: nowrap;
  font-size: ${props => props.fontSize};

  font-size: 0.85rem;
  color: ${Colors.Grey};
  padding-right: 1em
`;

export const DropDown = props => {

  const [
    state,
    setState,
  ] = React.useState({
    expanded: false,
    filter: '',
  });

  const onFilter = (filter) => {

    setState(prevState => ({
      ...prevState,
      filter,
    }));

    typeof props.onFilter === 'function' && props.onFilter({
      id: props.id,
      value: filter
    });
  };

  const onClick = e => {

    if (!e || e.defaultPrevented || props.disabled) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      expanded: !prevState.expanded,
    }));
  };

  const createItems = values => {

    if (!Array.isArray(values) || !values.length) {
      return [];
    }

    let items = [];

    for (const value of values) {

      if (!value || (typeof value.name !== 'string' && typeof value.label !== 'string')) {
        continue;
      }

      if (value.name) {

        items.push(

          <Item
            key={`drop-down-item-${value.key || value.name.toLowerCase()}`}
            padding={props.padding}
            colorHover={props.colorHover}
            backgroundColorHover={props.backgroundColorHover}
            fontSize={props.fontSize}
            onClick={() => {

              props.onSelect({
                id: props.id,
                value
              });

              setState(prevState => ({
                ...prevState,
                expanded: !prevState.expanded,
              }));
            }}>

            {value.name}
          </Item>
        );

      } else {

        items.push(

          <ItemLabel
            key={`drop-down-label-${value.key || value.name.toLowerCase()}`}
            padding={props.padding}
            fontSize={props.fontSize}>

            {value.label}
          </ItemLabel>
        );
      }
    }

    return items;
  };

  const items = createItems(props.values);

  const containerProps = {
    ...props
  };

  delete containerProps.onFilter;

  delete containerProps.onSelect;

  return (

    <Container
      {...containerProps}>

      <HFillContainer onClick={onClick}>

        {(!!props.value && props.value.name) || props.placeholder}

      </HFillContainer>

      {!props.disabled &&

        <ArrowContainer
          fontSize={props.fontSize}>

          {(state.expanded && '▲') || '▼'}
        </ArrowContainer>
      }

      {!props.disabled && state.expanded &&

        <ItemsContainer
          padding={props.padding}
          width={props.width}
          height={props.height}
          maxHeight={props.maxHeight}
          border={props.border}
          color={props.color}
          backgroundColor={props.backgroundColor}
          boxShadow={props.boxShadow}>


          {typeof props.onFilter === 'function' &&

            <Item>

              <HFillContainer>

                <SearchBox
                  id={`dropdown-search`}
                  width={'100%'}
                  value={state.filter}
                  onChange={onFilter} />

              </HFillContainer>

            </Item>
          }

          {items}
        </ItemsContainer>
      }
    </Container>
  );
};

DropDown.displayName = 'DropDown';

DropDown.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    name: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  value: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    name: PropTypes.string,
    label: PropTypes.string
  }),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  colorDisabled: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundColorHover: PropTypes.string,
  backgroundColorDisabled: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  boxShadow: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  fontSize: PropTypes.string,
  onSelect: PropTypes.func,
  onFilter: PropTypes.func,
};

DropDown.defaultProps = {
  placeholder: 'Select',
  disabled: false,
  color: Colors.White,
  colorHover: Colors.White,
  colorDisabled: Colors.White,
  backgroundColor: Colors.Transparent,
  backgroundColorHover: Colors.LighterBlue,
  backgroundColorDisabled: Colors.Grey,
  border: `1px solid ${Colors.Blue}`,
  borderRadius: '2rem',
  boxShadow: Shadows.NoOffsetSlightGradientTransparentBlack,
  width: '3.5rem',
  height: '1.5rem',
  maxHeight: '10rem',
  padding: '0.75rem 1rem',
  margin: '0',
  fontSize: '0.8rem',
  onSelect: () => {},
  onFilter: undefined,
};
