import {
  FetchApi
} from 'api/fetch';

export const SettingsApi = {

  getRoles: async => {

    return FetchApi.send(
      'system/permissions/roles', {
        method: 'GET'
      }
    )
  },

  addRole: async(
    {
      DisplayName,
      Description
    }
  ) => {

    if (!DisplayName) {
      throw Error('Unable to add role, no display name found');
    }

    return FetchApi.send(
      'system/permissions/roles', {
        method: 'POST',
        body: {
          DisplayName: DisplayName,
          Description: Description
        }
      }
    );
  },

  updateRole: async(
    RoleID,
    {
      DisplayName,
      Description
    }
  ) => {

    if (!RoleID) {
      throw Error('No Role ID found for roles');
    }

    return FetchApi.send(
      'system/permissions/roles/:RoleID', {
        method: 'PUT',
        path: {
          RoleID
        },
        body: {
          DisplayName: DisplayName,
          Description: Description
        }
      }
    );
  },

  getRoleTasks: async RoleID => {

    if(!RoleID) {
      throw Error('Unable to get Role Tasks for invalid Role ID.');
    }

    return FetchApi.send(
      'system/permissions/roles/:RoleID/tasks', {
        method: 'GET',
        path: {
          RoleID
        }
      }
    )
  },

  linkRoleTasks: async(
    RoleID,
    tasks
  ) => {

    if (!RoleID) {
      throw Error('No Role ID found for tasks');
    }

    if(!Array.isArray(tasks) || !tasks.length) {
      throw new Error('No tasks, provide a task');
    }

    return FetchApi.send(
      'system/permissions/roles/:RoleID/tasks', {
        method: 'POST',
        path: {
          RoleID
        },
        body: tasks
      }
    );
  },

  getRolesGroups: async => {

    return FetchApi.send(
      'system/permissions/rolegroups', {
        method: 'GET'
      }
    )
  },

  addRoleGroup: async(
    {
      DisplayName,
      Description
    }
  ) => {

    if (!DisplayName) {
      throw Error('Unable to add role, no display name found');
    }

    return FetchApi.send(
      'system/permissions/rolegroups', {
        method: 'POST',
        body: {
          DisplayName: DisplayName,
          Description: Description
        }
      }
    );
  },

  updateRoleGroup: async(
    RoleGroupID,
    {
      DisplayName,
      Description
    }
  ) => {

    if (!RoleGroupID) {
      throw Error('No Role Group ID found');
    }

    return FetchApi.send(
      'system/permissions/rolegroups/:RoleGroupID', {
        method: 'PUT',
        path: {
          RoleGroupID
        },
        body: {
          DisplayName: DisplayName,
          Description: Description
        }
      }
    );
  },

  getRoleGroupRoles: async RoleGroupID => {

    if (!RoleGroupID) {
      throw Error('Unable to get Role Group Roles, no RoleGroupId supplied');
    }

    return FetchApi.send(
      'system/permissions/rolegroups/:RoleGroupID/roles', {
        method: 'GET',
        path: {
          RoleGroupID
        }
      }
    );
  },

  linkRoleGroupRole: async (
    RoleGroupID,
    roles,
  ) => {

    if (!RoleGroupID) {
      throw Error('Unable to add role, no role group id found');
    }

    if(!Array.isArray(roles) || !roles.length) {
      throw new Error('No roles, provide roles');
    }

    return FetchApi.send(
      'system/permissions/rolegroups/:RoleGroupID/roles', {
        method: 'POST',
        path: {
          RoleGroupID
        },
        body: roles
      }
    );
  },

};
