import {
  Paths,
  Icons,
} from 'ui/common/constants';

const MenuItemPermissionKeys = {
  Insights: 'INSIGHT_VIEW_UI-MENUITEM',
  MembersManage: 'MEMBERS_MANAGE_UI-MENUITEM',
  MembersTransfers: 'MEMBERS_TRANSFERS_UI-MENUITEM',
  MembersAddNewMember: 'MEMBERS_CREATE-NEW-MEMBER_UI-MENUITEM',
  MembersReports: 'MEMBERS_REPORTS_UI-MENUITEM',
  MembersPivotReport: 'MEMBERS_PIVOT-REPORTS_UI-MENUITEM',
  OrganisationsDashboard: 'ORGANISATIONS_DASHBOARD_UI-MENUITEM',
  OrganisationsManage: 'ORGANISATIONS_MANAGE_UI-MENUITEM',
  OrganisationsReports: 'ORGANISATIONS_REPORTS_UI-MENUITEM',
  OrganisationsVenues: 'ORGANISATIONS_VENUES_UI-MENUITEM',
  OrganisationsSurveys: 'ORGANISATIONS_SURVEYS_UI-MENUITEM',
  OrganisationsForms: 'ORGANISATIONS_FORMS_UI-MENUITEM',
  OrganisationsPlanBudget: 'ORGANISATIONS_PLAN_BUDGET_UI-MENUITEM',
  TournamentsManage: 'TOURNAMENTS_MANAGE_UI-MENUITEM',
  TournamentsNewTournament: 'TOURNAMENTS_NEW_TOURNAMENT_UI-MENUITEM',
  TournamentsManageFixtures: 'TOURNAMENTS_MANAGE_FIXTURES_UI-MENUITEM',
  TournamentsInsightReports: 'TOURNAMENTS_INSIGHT_REPORTS_UI-MENUITEM',
  TournamentsPivotReports: 'TOURNAMENTS_PIVOT_REPORT_UI-MENUITEM',
  SquadsManage: 'SQUADS_MANAGE_UI-MENUITEM',
  SquadsNewSquad: 'SQUADS_NEW_SQUAD_UI-MENUITEM',
  SquadsInsightReports: 'SQUADS_INSIGHT_REPORT_UI-MENUITEM',
  SquadsPivotReports: 'SQUADS_PIVOT_REPORT_UI-MENUITEM',
  Medical: 'MEDICAL_VIEW_UI-MENUITEM',
  SettingsCountries: 'SETTINGS_COUNTRIES_UI-MENUITEM',
  SettingsVenues: 'SETTINGS_VENUES_UI-MENUITEM',
  SettingsGroupsRoles: 'SETTINGS_GROUPS_ROLES_UI-MENUITEM',
  SettingsOrganisationStructures: 'SETTINGS_ORGANISATION_STRUCTURES_UI-MENUITEM',
  SettingsSportCodes: 'SETTINGS_SPORT_CODES_UI-MENUITEM',
  SettingsQualifications: 'SETTINGS_QUALIFICATIONS_UI-MENUITEM',
  SettingsAchievements: 'SETTINGS_ACHIEVEMENTS_UI-MENUITEM',
  SettingsLibraryToolbox: 'SETTINGS_LIBRARY_TOOLBOX_UI-MENUITEM',
  SettingsSurveys: 'SETTINGS_SURVEYS_UI-MENUITEM',
  SettingsNotifications: 'SETTINGS_NOTIFICATIONS_UI-MENUITEM',
  SettingsEmailTemplates: 'SETTINGS_EMAIL_TEMPLATES_UI-MENUITEM',
  SettingsSupport: 'SETTINGS_SUPPORT_UI-MENUITEM'
}

export const ProfileMenuItems = {
  myProfile: {
    key: 'my-profile',
    display: 'My Profile',
    route: Paths.MembersProfile,
    icon: undefined
  },
  myTeams: {
    key: 'my-teams',
    display: 'My Teams',
    route: Paths.Tournaments,
    icon: undefined
  },
  resetPassword: {
    key: 'reset-password',
    display: 'Reset password',
    route: Paths.Forgot,
    icon: undefined
  },
  signOut: {
    key: 'sign-out',
    display: 'Sign out',
    route: Paths.Login,
    icon: undefined
  }
};

export const SideBarItems =  {
  insight: {
    key: 'insight',
    display: 'inSight',
    route: Paths.Insights,
    icon: Icons.Eye,
    permissionKey: MenuItemPermissionKeys.Insights
  },
  members: {
    key: 'members',
    display: 'Members',
    route: Paths.Members,
    icon: Icons.People,
    items: {
      manage: {
        key: 'manage',
        display: 'Manage',
        route: Paths.MembersManage,
        icon: Icons.Wrench,
        permissionKey: MenuItemPermissionKeys.MembersManage
      },
      transfers: {
        key: 'transfers',
        display: 'Transfers',
        route: Paths.MembersTransfer,
        icon: Icons.ArrowRightCircle,
        permissionKey: MenuItemPermissionKeys.MembersTransfers
      },
      newMember: {
        key: 'new-member',
        display: 'Add New Member',
        route: Paths.MembersAddNewMember,
        icon: Icons.Plus,
        permissionKey: MenuItemPermissionKeys.MembersAddNewMember
      },
      reports: {
        key: 'reports',
        display: 'Reports',
        route: Paths.MembersReports,
        icon: Icons.Notebook,
        permissionKey: MenuItemPermissionKeys.MembersReports
      },
      pivotReport: {
        key: 'pivot-report',
        display: 'Pivot Report',
        route: Paths.MembersPivotReports,
        icon: Icons.BookOpen,
        permissionKey: MenuItemPermissionKeys.MembersPivotReport
      }
    }
  },
  organisations: {
    key: 'organisations',
    display: 'Organisations',
    route: Paths.Organisations,
    icon: Icons.Globe,
    items: {
      dashboard: {
        key: 'dashboard',
        display: 'Dashboard',
        route: Paths.OrganisationsDashboard,
        icon: Icons.Grid,
        permissionKey: MenuItemPermissionKeys.OrganisationsDashboard
      },
      manage: {
        key: 'manage',
        display: 'Manage',
        route: Paths.OrganisationsManage,
        icon: Icons.Wrench,
        permissionKey: MenuItemPermissionKeys.OrganisationsManage
      },
      reports: {
        key: 'reports',
        display: 'Reports',
        route: Paths.OrganisationsReports,
        icon: Icons.Notebook,
        permissionKey: MenuItemPermissionKeys.OrganisationsReports
      },
      venues: {
        key: 'venues',
        display: 'Venues',
        route: Paths.OrganisationsVenues,
        icon: Icons.LocationPin,
        permissionKey: MenuItemPermissionKeys.OrganisationsVenues
      },
      surveys: {
        key: 'surveys',
        display: 'Surveys',
        route: Paths.OrganisationsSurveys,
        icon: Icons.List,
        permissionKey: MenuItemPermissionKeys.OrganisationsSurveys
      },
      forms: {
        key: 'forms',
        display: 'Forms',
        route: Paths.OrganisationsForms,
        icon: Icons.Pencil,
        permissionKey: MenuItemPermissionKeys.OrganisationsForms
      },
      planBudget: {
        key: 'plan-budget',
        display: 'Plan & Budget',
        route: Paths.OrganisationsPlanBudget,
        icon: Icons.Calendar,
        permissionKey: MenuItemPermissionKeys.OrganisationsPlanBudget
      }
    }
  },
  tournaments: {
    key: 'tournaments',
    display: 'Tournaments',
    route: Paths.Tournaments,
    icon: Icons.Trophy,
    items: {
      manage: {
        key: 'manage',
        display: 'Manage',
        route: Paths.TournamentsManage,
        icon: Icons.Wrench,
        permissionKey: MenuItemPermissionKeys.TournamentsManage
      },
      newTournament: {
        key: 'new-tournament',
        display: 'New Tournament',
        route: Paths.TournamentsNewTournament,
        icon: Icons.Plus,
        permissionKey: MenuItemPermissionKeys.TournamentsNewTournament
      },
      fixtures: {
        key: 'fixtures',
        display: 'Manage Fixtures',
        route: Paths.TournamentsManageFixtures,
        icon: Icons.Calendar,
        permissionKey: MenuItemPermissionKeys.TournamentsManageFixtures
      },
      insightReports: {
        key: 'insight-reports',
        display: 'InSight Reports',
        route: Paths.TournamentsInsightReports,
        icon: Icons.Notebook,
        permissionKey: MenuItemPermissionKeys.TournamentsInsightReports
      },
      pivotReports: {
        key: 'pivot-reports',
        display: 'Pivot Reports',
        route: Paths.TournamentsPivotReports,
        icon: Icons.Layers,
        permissionKey: MenuItemPermissionKeys.TournamentsPivotReports
      }
    }
  },
  squads: {
    key: 'squads',
    display: 'Squads',
    route: Paths.Squads,
    icon: Icons.People,
    items: {
      manage: {
        key: 'manage',
        display: 'Manage',
        route: Paths.SquadsManage,
        icon: Icons.Wrench,
        permissionKey: MenuItemPermissionKeys.SquadsManage
      },
      newSquad: {
        key: 'new-squad',
        display: 'New Squad',
        route: Paths.SquadsNewSquad,
        icon: Icons.Plus,
        permissionKey: MenuItemPermissionKeys.SquadsNewSquad
      },
      insightReports: {
        key: 'insight-reports',
        display: 'inSight Reports',
        route: Paths.SquadsInsightReports,
        icon: Icons.Notebook,
        permissionKey: MenuItemPermissionKeys.SquadsInsightReports
      },
      pivotReports: {
        key: 'pivot-reports',
        display: 'Pivot Reports',
        route: Paths.SquadsPivotReports,
        icon: Icons.Layers,
        permissionKey: MenuItemPermissionKeys.SquadsPivotReports
      }
    }
  },
  medical: {
    key: 'medical',
    display: 'Medical',
    route: Paths.Medical,
    icon: Icons.Heart,
    permissionKey: MenuItemPermissionKeys.Medical
  },
  settings: {
    key: 'settings',
    display: 'Settings',
    route: Paths.Settings,
    icon: Icons.Settings,
    items: {
      countries: {
        key: 'countries',
        display: 'Countries',
        route: Paths.SettingsCountries,
        icon: Icons.GlobeAlt,
        permissionKey: MenuItemPermissionKeys.SettingsCountries
      },
      venues: {
        key: 'venues',
        display: 'Venues',
        route: Paths.SettingsVenues,
        icon: Icons.Home,
        permissionKey: MenuItemPermissionKeys.SettingsVenues
      },
      groupsRoles: {
        key: 'groups-roles',
        display: 'Groups & Roles',
        route: Paths.SettingsGroupsRoles,
        icon: Icons.Key,
        permissionKey: MenuItemPermissionKeys.SettingsGroupsRoles
      },
      organisationStructures: {
        key: 'organisation-structures',
        display: 'Organisation Structures',
        route: Paths.SettingsOrganisationStructures,
        icon: Icons.Organization,
        permissionKey: MenuItemPermissionKeys.SettingsOrganisationStructures
      },
      sportCodes: {
        key: 'sport-codes',
        display: 'Sport Codes',
        route: Paths.SettingsSportCodes,
        icon: Icons.Tag,
        permissionKey: MenuItemPermissionKeys.SettingsSportCodes
      },
      qualifications: {
        key: 'qualifications',
        display: 'Qualifications',
        route: Paths.SettingsQualifications,
        icon: Icons.Docs,
        permissionKey: MenuItemPermissionKeys.SettingsQualifications
      },
      achievements: {
        key: 'achievements',
        display: 'Achievements',
        route: Paths.SettingsAchievements,
        icon: Icons.Trophy,
        permissionKey: MenuItemPermissionKeys.SettingsAchievements
      },
      libraryToolbox: {
        key: 'library-toolbox',
        display: 'Library Toolbox',
        route: Paths.SettingsLibraryToolbox,
        icon: Icons.Wrench,
        permissionKey: MenuItemPermissionKeys.SettingsLibraryToolbox
      },
      surveys: {
        key: 'surveys',
        display: 'Surveys',
        route: Paths.SettingsSurveys,
        icon: Icons.Pencil,
        permissionKey: MenuItemPermissionKeys.SettingsSurveys
      },
      notifications: {
        key: 'notifications',
        display: 'Notifications',
        route: Paths.SettingsNotifications,
        icon: Icons.Info,
        permissionKey: MenuItemPermissionKeys.SettingsNotifications
      },
      emailTemplates: {
        key: 'email-templates',
        display: 'Email Templates',
        route: Paths.SettingsEmailTemplates,
        icon: Icons.EnvelopeLetter,
        permissionKey: MenuItemPermissionKeys.SettingsEmailTemplates
      },
      support: {
        key: 'support',
        display: 'Support',
        route: Paths.SettingsSupport,
        icon: Icons.Support,
        permissionKey: MenuItemPermissionKeys.SettingsSupport
      },
    },
  }
};

export const FooterItems = [{
  key: 'footer-item1',
  display: 'FooterDisplayOne',
  route: Paths.Insights,
  icon: '/assets/icons/icon1.svg'
}];
