import {
  Paths,
  withLazy,
} from 'ui/common';

const dImport = import('ui/landing');

export const LandingRoutes = {
  landing: {
    component: withLazy(
      dImport,
      'Landing'
    ),
    path: Paths.Landing,
  },
};
