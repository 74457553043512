import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

const UserPool = new CognitoUserPool({
  UserPoolId: 'eu-west-1_16nNqbqeS',
  ClientId: '54akdkecv9neibv7fapo9r3p80',
});

export const IdentityStatus = {
  LoggedIn: 'logged-in',
  LoggedOut: 'logged-out',
  NewPassword: 'new-password',
  CodeSent: 'code-sent',
  PasswordConfirmed: 'password-confirmed',
  Refresh: 'refresh',
};

export const IdentityApi = {

  CognitoUser: UserPool.getCurrentUser(),

  getSession: async () => {

    return new Promise((
      resolve,
      reject,
    ) => {

      if (!IdentityApi.CognitoUser) {
        resolve();
      }

      IdentityApi.CognitoUser.getSession((
        e,
        session
      ) => (e && reject(e)) || resolve(session));
    });
  },

  logout: () => {

    IdentityApi.CognitoUser && IdentityApi.CognitoUser.signOut();
    IdentityApi.CognitoUser = null;

    return {
      destination: undefined,
      user: undefined,
      memberId: undefined,
      tokens: undefined,
      status: IdentityStatus.LoggedOut,
      userAttributes: undefined,
    };
  },

  login: async (
    user,
    password,
  ) => {

    const authDetails = new AuthenticationDetails({
      Username: user,
      Password: password,
    });

    IdentityApi.CognitoUser = new CognitoUser({
      Username: user,
      Pool: UserPool,
    });

    return new Promise((
      resolve,
      reject,
    ) => {

      IdentityApi.CognitoUser.authenticateUser(
        authDetails, {
        onSuccess: result => {

          const accessToken = result
            .getAccessToken()
            .getJwtToken();

          const refreshToken = result
            .getRefreshToken()
            .getToken();

          resolve({
            memberId: result.getIdToken().payload['custom:memberid'],
            user: result.getIdToken().payload['preferred_username'],
            tokens: {
              access: accessToken,
              refresh: refreshToken,
            },
            status: IdentityStatus.LoggedIn,
          });
        },

        newPasswordRequired: (
          userAttributes,
          requiredAttributes
        ) => {

          delete userAttributes.email_verified;

          resolve({
            destination: undefined,
            user,
            memberId: undefined,
            tokens: undefined,
            status: IdentityStatus.NewPassword,
            userAttributes,
          });
        },

        onFailure: e => reject(e),
      });
    });
  },

  completeNewPassword: async (
    user,
    newPassword,
    userAttributes,
  ) => {

    return new Promise((
      resolve,
      reject,
    ) => {

      if (!IdentityApi.CognitoUser) {

        reject('Attempted to complete new password with invalid user');
        return;
      }

      IdentityApi.CognitoUser.completeNewPasswordChallenge(
        newPassword,
        userAttributes, {
        onSuccess: result => {

          const accessToken = result
            .getAccessToken()
            .getJwtToken();

          const refreshToken = result
            .getRefreshToken()
            .getToken();

          resolve({
            destination: undefined,
            user,
            memberId: result.getIdToken().payload['custom:memberid'],
            tokens: {
              access: accessToken,
              refresh: refreshToken,
            },
            status: IdentityStatus.LoggedIn,
            userAttributes,
          });
        },
        onFailure: e => reject(e),
      }
      );
    });
  },

  forgot: async user => {

    return new Promise((
      resolve,
      reject,
    ) => {

      if (!user) {

        reject('Attempted to complete new password with invalid user');
        return;
      }

      IdentityApi.CognitoUser = new CognitoUser({
        Username: user,
        Pool: UserPool,
      });

      IdentityApi.CognitoUser.forgotPassword({
        onSuccess: result => {

          resolve({
            destination: result.CodeDeliveryDetails.Destination,
            user,
            tokens: undefined,
            status: IdentityStatus.CodeSent,
            userAttributes: undefined,
          });
        },
        onFailure: e => reject(e),
      }
      );
    });
  },

  confirmPassword: async (
    user,
    code,
    password,
  ) => {

    return new Promise((
      resolve,
      reject,
    ) => {

      if (!IdentityApi.CognitoUser) {

        reject('Attempted to complete new password with invalid user');
        return;
      }

      IdentityApi.CognitoUser.confirmPassword(
        code,
        password, {
        onSuccess: () => {

          resolve({
            destination: undefined,
            user,
            tokens: undefined,
            status: IdentityStatus.PasswordConfirmed,
            userAttributes: undefined,
          });
        },
        onFailure: e => reject(e),
      });
    });
  },

  changePassword: async (
    oldPassword,
    newPassword,
  ) => {

    return new Promise((
      resolve,
      reject,
    ) => {

      if (!IdentityApi.CognitoUser) {

        reject('Attempted to change password with invalid session');
        return;
      }

      IdentityApi.CognitoUser.changePassword(
        oldPassword,
        newPassword, (
          e,
          result
        ) => (e && reject(e)) || resolve(result),
      );
    });
  },

  refresh: async () => {

    const session = await IdentityApi.getSession();

    let refreshToken = session
      .getRefreshToken()
      .getToken();

    return new Promise((
      resolve,
      reject
    ) => {

      if (!IdentityApi.CognitoUser) {

        reject(Error('Session expired please login again to continue'));
        return;
      }

      if (!refreshToken) {

        reject(Error('Session expired please sign in again to continue'));
        return;
      }

      IdentityApi.CognitoUser.refreshSession({
        RefreshToken: refreshToken,
        getToken: () => refreshToken,
      },
        (
          e,
          result
        ) => {

          if (e || !result) {

            resolve(e);
            return;
          }

          const accessToken = result
            .getAccessToken()
            .getJwtToken();

          const refreshToken = result
            .getRefreshToken()
            .getToken();

          resolve({
            tokens: {
              access: accessToken,
              refresh: refreshToken,
            },
          });
        });
    });
  },
};
