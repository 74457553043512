import {
  Paths,
  withLazy
} from 'ui/common';

const dImport = import('ui/medical');

export const MedicalRoutes = {
  medical: {
    component: withLazy(
      dImport,
      'Medical'
    ),
    path: Paths.Medical
  },
};
