import
  React
  from 'react';

import {
  useRecoilState,
} from 'recoil';

import {
  Validation,
} from 'common';

import {
  Paths,
  InputTypes,
  VFillContainerV,
  Button,
  Input,
  Link,
  useShowErrorToast,
} from 'ui/common';

import {
  ActionContainer,
  IdentityScene,
} from 'ui/identity/components';

import {
  IdentityAtom,
} from 'ui/atoms';

import {
  IdentityApi
} from 'api';

export const ConfirmPassword = props => {

  const [
    identity,
    setIdentity,
  ] = useRecoilState(IdentityAtom);

  const [
    state,
    setState,
  ] = React.useState({
    code: '',
    password: '',
    isValid: false,
    isBusy: false,
  });

  const showErrorToast = useShowErrorToast('Confirm password');

  const onInputChange = e => {

    if (!e || e.defaultPrevented) {
      return;
    }

    e.preventDefault();

    let inputs = {
      'confirm-password-code': 'code',
      'confirm-password-password': 'password',
    };

    const key = inputs[e.target.id];
    const value = e.target.value;

    setState(prevState => {

      let state = {
        ...prevState,
        [key]: value,
      };

      return ({
        ...state,
        isValid: Validation.isValidUserName(state.code) && Validation.isValidPassword(state.password),
      });
    });
  };

  const onConfirm = () => {

    if (!state.isValid || state.isBusy) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      isBusy: true,
    }));

    IdentityApi
      .confirmPassword(
        identity.user,
        state.code,
        state.password,
      )
      .then(identity => {

        setIdentity(identity);

        setState(prevState => ({
          ...prevState,
          code: '',
          password: '',
          isBusy: false,
        }));

        props.history.push(Paths.Login);
      })
      .catch(e => {

        setState(prevState => ({
          ...prevState,
          isBusy: false,
        }));

        showErrorToast(e);
      });
  };

  return (

    <IdentityScene
      headline={'LEAVING A FOOTPRINT FOR OTHERS TO FOLLOW'}
      subtext={'Please use your code to reset your password.'}
      formHeading={'Confirm password'}>

      <VFillContainerV>

        <Input
          id={'confirm-password-code'}
          name={'confirm-password-code'}
          key={'confirm-password-code'}
          type={InputTypes.Text}
          readOnly={state.isBusy}
          label={'Code'}
          value={state.code}
          onChange={onInputChange}/>

        <Input
          id={'confirm-password-password'}
          name={'confirm-password-password'}
          key={'confirm-password-password'}
          type={InputTypes.Password}
          readOnly={state.isBusy}
          label={'New password'}
          value={state.password}
          onChange={onInputChange}/>

      </VFillContainerV>

      <ActionContainer>

        <Link
          onClick={() => props.history.push(Paths.Forgot)}>

          Go back
        </Link>

        <Link
          onClick={() => props.history.push(Paths.Login)}>

          Login
        </Link>

        <Button
          text={'CONFIRM'}
          disabled={!state.isValid}
          busy={state.isBusy}
          onClick={onConfirm}/>

      </ActionContainer>

    </IdentityScene>
  );
};

ConfirmPassword.displayName = 'ConfirmPassword';
