import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors,
} from 'ui/common/constants';

import {
  HContainerV,
} from 'ui/common/components/Containers';

import {
  Heading6,
} from 'ui/common/components/Type';

const Cell = styled(Heading6)`
  display: flex;
  cursor: pointer;
  padding: 0.1rem 0.75rem;
  height: 100%;
  
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.visible && Colors.Transparent) || Colors.Black};
`;

const Container = styled(HContainerV)`
  height: 2rem;
  user-select: none;
  border-radius: 50vmin;
  background-color: ${Colors.LightGreyTransparent26};
  overflow: hidden;
`;

export const ColumnToggle = props => {

  const createColumnItems = columns => {

    if (!columns) {
      return [];
    }

    let items = [];

    for (const [key, value] of Object.entries(columns)) {

      if (!value) {
        continue;
      }

      items.push(

        <Cell
          key={`column-${key}`}
          visible={!(value.hasOwnProperty('visible') && !value.visible)}
          onClick={() => typeof props.onColumnChange === 'function' && props.onColumnChange({
            [key]: {
              ...value,
              visible: value.hasOwnProperty('visible') && !value.visible,
            },
          })}>

          {value.display}
        </Cell>
      );
    }

    return items;

  };

  const columnItems = createColumnItems(props.columns);

  return (

    <Container>
      {columnItems}
    </Container>
  );
};

ColumnToggle.displayName = 'ColumnToggle';

ColumnToggle.propTypes = {
  columns: PropTypes.object,
  onColumnChange: PropTypes.func,
};

ColumnToggle.defaultProps = {
  columns: undefined,
  onColumnChange: undefined,
};
