import {
  atom
} from 'recoil';

export const IdentityAtom = atom({
  key: 'identity',
  default: {
    destination: undefined,
    user: undefined,
    tokens: undefined,
    status: undefined,
    userAttributes: undefined,
  },
});

export const ToastAtom = atom({
  key: 'toast',
  default: {
    visible: undefined,
    heading: undefined,
    message: undefined,
    type: undefined,
  },
});

export const SceneAtom = atom({
  key: 'scene',
  default: {
    toolBarVisible: true,
    allowedToolbarItems: undefined,
    activeItem: undefined,
    activeSubItem: undefined,
    dropDownVisible: false,
  }
});

export const MemberAtom = atom({
  key: 'member',
  default:{
    updated: false,
    memberId: undefined,
    member: undefined,
    permissions: undefined,
  }
});

export const EnumeratorAtom = atom({
  key: 'enumerator',
  default: {
    binaryChoice: [{
      id: 0,
      name: 'No'
    }, {
      id: 1,
      name: 'Yes'
    }],
    countries: undefined,
    genders: undefined,
    relationship: undefined,
    status: undefined
  }
});

