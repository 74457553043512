import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors,
} from 'ui/common/constants'

import {
  Button
} from 'ui/common/components/Button';

import {
  HContainer,
  HContainerV,
} from 'ui/common/components/Containers';

import {
  DropDown
} from 'ui/common/components/DropDown';

import {
  SearchBox
} from 'ui/common/components/SearchBox';

import {
  ColumnToggle
} from 'ui/common/components/table/ColumnToggle';

const Container = styled(HContainerV)`
  padding: 0.5rem;
  justify-content: space-between;
`;

export const ActionHeader = props => {

  const [
    state,
    setState
  ] = React.useState({
    filter: '',
  });

  const onFilter = filter => {

    setState(prevState => ({
      ...prevState,
      filter,
    }));

    typeof props.onFilter === 'function' && props.onFilter(filter);
  };

  return (

    <Container>

      { typeof props.onFilter === 'function' &&

        <SearchBox
          id={'search-box'}
          width={'15rem'}
          value={state.filter}
          onChange={onFilter}/>
      }

      <ColumnToggle
        columns={props.columns}
        onColumnChange={props.onColumnChange}/>

      <HContainer>

        { typeof props.onExport === 'function' &&

          <Button
            margin={'0 0.5rem'}
            text={'EXPORT CSV'}
            onClick={props.onExport}/>
        }

        { !!props.actions &&

          <DropDown
            id={'action-header-actions'}
            width={'5rem'}
            backgroundColor={Colors.DarkBlack}
            value={undefined}
            values={props.actions}
            placeholder={'ACTIONS'}
            onSelect={props.onAction}/>
        }

      </HContainer>

    </Container>
  );
};

ActionHeader.displayName = 'ActionHeader';

ActionHeader.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  columns: PropTypes.object,
  onColumnChange: PropTypes.func,
  onFilter: PropTypes.func,
  onAction: PropTypes.func,
};

ActionHeader.defaultProps = {
  actions: undefined,
  columns: undefined,
  onColumnChange: undefined,
  onFilter: undefined,
  onAction: undefined,
};
