import
  React
from 'react';

import {
  useRecoilState
} from 'recoil';

import {
  useLog
} from 'common';

import {
  IdentityApi
} from 'api';

import {
  IdentityAtom
} from 'ui/atoms';

import {
  Paths
} from 'ui/common/constants';

export const withAuthorisation = Component => props => {

  const [
    identity,
    setIdentity,
  ] = useRecoilState(IdentityAtom);

  const log = useLog({
    name: `withAuthorisation(${Component.displayName})`,
  });

  const validateIdentity = () => {

    IdentityApi
      .getSession()
      .then(session => {

        if (!session) {
          throw Error('Session is invalid');
        }

        if (session.isValid()) {

          if (!identity.memberId || !identity.user) {

            const memberId = session.getIdToken().payload['custom:memberid'];
            const user = session.getIdToken().payload['preferred_username'];

            setIdentity(prevIdentity => ({
              ...prevIdentity,
              memberId,
              user,
            }));
          }

          return;
        }

        return IdentityApi.refresh();
      })
      .then(identity => {

        if (!identity) {
          return;
        }

        setIdentity(prevIdentity => ({
          ...prevIdentity,
          ...identity,
        }));
      })
      .catch(e => {

        log.error(e)
        setIdentity(IdentityApi.logout());

        props.history.push(Paths.Login);
      });
  };

  validateIdentity();

  return (
    <Component {...props} />
  );
};

withAuthorisation.displayName = 'withAuthorisation';
