import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  ToolBarItem
} from 'ui/common/components/ToolBarItem';

import {
  Colors,
} from 'ui/common/constants';

const ToolBarContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  overflow: hidden;
  text-align: center;
  background-color: ${props => props.backgroundColor};
  transition: width 0.3s ease-out;
  
  min-width: ${props => props.minWidth};
  width: ${props => props.width};
  padding: ${props => props.padding};
  
  ${props => props.border && `border-left: ${props.border} ;`}
  ${props => props.borderLeft && `border-left: ${props.borderLeft} ;`}
  ${props => props.borderRight && `border-right: ${props.borderRight} ;`}
  ${props => props.borderTop && `border-top: ${props.borderTop} ;`}
  ${props => props.borderBottom && `border-bottom: ${props.borderBottom} ;`}
  
  ${props => props.borderRadius && `border-radius: ${props.borderRadius};`}
  ${props => props.borderTopLeftRadius && `border-top-left-radius: ${props.borderTopLeftRadius};`}
  ${props => props.borderTopRightRadius && `border-top-right-radius: ${props.borderTopRightRadius};`}
  ${props => props.borderBottomLeftRadius && `border-bottom-left-radius: ${props.borderBottomLeftRadius};`}
  ${props => props.borderBottomRightRadius && `border-bottom-right-radius: ${props.borderBottomRightRadius};`} 
`;

export const ToolBar = props => {

  const createListItems = items => {

    if (!items && !!Object.keys(items).length) {
      return [];
    }

    let listItems = [];

    for (const [key, value] of Object.entries(items)) {

      if (!value || (!value.icon && !value.display)) {
        continue;
      }

      listItems.push(

        <ToolBarItem
          key={`tool-bar-item-${key}`}
          flexDirection={props.itemDirection}
          item={value}
          iconSize={props.iconSize}
          active={props.item && props.item.key === value.key}
          indicator={props.itemIndicator}
          onClick={props.onClick} />
      );
    }

    return listItems;
  }

  const toolBarItems = createListItems(props.items);

  return (

    <ToolBarContainer
      {...props}
      onClick={undefined}>

      {toolBarItems}
    </ToolBarContainer>
  );
};

ToolBar.displayName = 'ToolBar';

ToolBar.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }),
  items: PropTypes.objectOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    icon: PropTypes.string,
    display: PropTypes.string,
    route: PropTypes.string
  })).isRequired,
  iconSize: PropTypes.string,
  justifyContent: PropTypes.string,
  flexDirection: PropTypes.string,
  itemDirection: PropTypes.string,
  itemIndicator: PropTypes.bool,
  backgroundColor: PropTypes.string,
  borderLeft: PropTypes.string,
  borderRight: PropTypes.string,
  borderTop: PropTypes.string,
  borderBottom: PropTypes.string,
  borderTopLeftRadius: PropTypes.string,
  borderTopRightRadius: PropTypes.string,
  borderBottomLeftRadius: PropTypes.string,
  borderBottomRightRadius: PropTypes.string,
  minWidth: PropTypes.string,
  width: PropTypes.string,
};

ToolBar.defaultProps = {
  flexDirection: 'column',
  itemDirection: 'column',
  itemIndicator: true,
  items: undefined,
  backgroundColor: Colors.Black,
  minWidth: 'auto',
  width: 'auto',
};
