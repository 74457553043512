import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors
} from 'ui/common/constants';

export const Text = styled.div`
  user-select: none;
  font-size: 0.85rem;
  
  color: ${props => props.color};
`;

Text.displayName = 'Text';

Text.propTypes = {
  color: PropTypes.string,
};

Text.defaultProps = {
  color: Colors.White,
};

export const Heading1 = styled(Text)`
  font-size: 2.2rem;
  font-weight: 500;
  padding-bottom: 10px;
  display: inline-block;
`;

Heading1.displayName = 'Heading1';

export const Heading2 = styled(Text)`
  font-size: 2rem;
  font-weight: 500;
`;

Heading2.displayName = 'Heading2';

export const Heading3 = styled(Text)`
  font-size: 1.6rem;
  font-weight: 500;
`;

Heading3.displayName = 'Heading3';

export const Heading4 = styled(Text)`
  font-size: 1.4rem;
  font-weight: 500;
`;

Heading4.displayName = 'Heading4';

export const Heading5 = styled(Text)`
  font-size: 1.15rem;
  font-weight: 500;
`;

Heading5.displayName = 'Heading5';

export const Heading6 = styled(Text)`
  font-size: 1rem;
  font-weight: 500;
`;

Heading6.displayName = 'Heading6';

export const Paragraph = styled.p`
  font-size: 0.85rem;
  line-height: 1.3rem;
  
  color: ${props => props.color};
`;

Paragraph.displayName = 'Paragraph';

Paragraph.propTypes = {
  color: PropTypes.string,
};

Paragraph.defaultProps = {
  color: Colors.White,
};
