import {
  IdentityRoutes,
  LandingRoutes,
  MembersRoutes,
  MedicalRoutes,
  OrganisationsRoutes,
  SquadsRoutes,
  TournamentsRoutes,
  SettingsRoutes,
  InsightsRoutes
} from 'ui/navigation/routes';

export * from 'ui/navigation/constants';
export * from 'ui/navigation/Navigator';
export * from 'ui/navigation/utilities';

export const Routes = Object.assign(
  {},
  IdentityRoutes,
  LandingRoutes,
  MembersRoutes,
  MedicalRoutes,
  OrganisationsRoutes,
  SquadsRoutes,
  TournamentsRoutes,
  SettingsRoutes,
  InsightsRoutes
);
