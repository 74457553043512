import
  React
from 'react';

import
  PropTypes
from 'prop-types';

import
  styled
from 'styled-components/macro';

import {
  HContainerV,
  HFillContainerV
} from 'ui/common/components/Containers';

import {
  Heading6,
} from 'ui/common/components/Type';

import {
  StatusPill
} from 'ui/common/components/StatusPill';

import {
  Icon
} from 'ui/common/components/Icon';

import {
  Colors
} from 'ui/common/constants';

const TitleContainer = styled(HFillContainerV)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.padding};
  border-bottom: 1px solid ${Colors.LightGrey};
`;

export const CardTitle = props =>

  <TitleContainer
    padding={props.padding}>

    <HContainerV>

      { !!props.icon &&

        <Icon
          size={'1.5rem'}
          padding={'0 0.75rem 0 0'}
          icon={props.icon}/>
      }

      <Heading6>
        {props.heading}
      </Heading6>

    </HContainerV>

    <StatusPill
      color={props.statusColor}>

      {props.statusText}
    </StatusPill>

  </TitleContainer>
;

CardTitle.displayName = 'CardTitle';

CardTitle.propTypes = {
  padding: PropTypes.string,
  icon: PropTypes.string,
  heading: PropTypes.string,
  statusColor: PropTypes.string,
  statusText: PropTypes.string
};

CardTitle.defaultProps = {
  padding: '0.75rem',
  icon: undefined,
  heading: undefined,
  statusColor: Colors.Blue,
  statusText: '',
};
