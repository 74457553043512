const logs = {};

export const useLog = ({
  name = 'unknown',
}) => {

  !logs[name] && (logs[name] = {

    info: message => console.log({
      name,
      message
    }),

    error: error => console.error(
      error, {
        name,
        message: error.message
      }
    ),
  });

  return logs[name];
};
