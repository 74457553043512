import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors,
  Order,
} from 'ui/common/constants';

import {
  HFillContainerV,
} from 'ui/common/components/Containers';

import {
  Heading6
} from 'ui/common/components/Type';

import {
  Input,
  InputTypes,
} from 'ui/common/components/Input';

const OrderCharacter = {
  [Order.Default]: '',
  [Order.Ascending]: '▲',
  [Order.Descending]: '▼',
};

const Container = styled(HFillContainerV)`

  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  
  border: ${props => props.border};
`;

const Cell = styled(Heading6)`

  user-select: none;
  cursor: ${props => props.cursor};
  
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem;
  font-weight: bold;
`;

const OrderIndicator = styled.div`
  padding: 0 0 0 0.5rem;
  font-size: 0.6rem;
`;

export const Header = props => {

  const createItems = columns => {

    if (!columns) {
      return [];
    }

    let items = (typeof props.onSelectAll === 'function'  && props.addAction && !props.readonly && [

      <Input
        key={'header-select-all'}
        minWidth={'0'}
        width={'3rem'}
        type={InputTypes.CheckBox}
        checked={props.selectAll}
        onChange={props.onSelectAll}/>
    ]) || [];

    for (const [key, value] of Object.entries(columns)) {

      if (!value || (value.hasOwnProperty('visible') && !value.visible)) {
        continue;
      }

      items.push(

        <Cell
          key={`header-${key}`}
          cursor={(!props.readonly && 'pointer') || 'auto'}
          onClick={() => typeof props.onColumnChange === 'function' && props.onColumnChange({
            [key]: {
              ...value,
              order: ((value.order === Order.Default || value.order === Order.Descending) && Order.Ascending) || Order.Descending,
            },
          })}>

          {value.display}

          { !!value.order && value.order !== Order.Default &&

            <OrderIndicator>
              {OrderCharacter[value.order]}
            </OrderIndicator>
          }

        </Cell>
      );
    }

    props.addAction && !props.readonly && items.push(

      <Cell
        key={'header-actions'}/>
    );

    return items;
  };

  const items = createItems(props.columns);

  return (

    <Container
      color={props.color}
      backgroundColor={props.backgroundColor}>

      {items}
    </Container>
  );
};

Header.displayName = 'Header';

Header.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  readonly: PropTypes.bool,
  addAction: PropTypes.bool,
  columns: PropTypes.object.isRequired,
  selectAll: PropTypes.bool,
  onSelectAll: PropTypes.func,
  onColumnChange: PropTypes.func,
};

Header.defaultProps = {
  color: Colors.White,
  backgroundColor: Colors.BlueCharcoal,
  border: '0',
  readonly: false,
  addAction: false,
  columns: undefined,
  selectAll: false,
  onSelectAll: undefined,
  onColumnChange: undefined,
};
