import {
  Paths,
  withLazy
} from 'ui/common';

const dImport = import('ui/organisations');

export const OrganisationsRoutes = {
  organisations: {
    component: withLazy(
      dImport,
      'Organisations'
    ),
    path: Paths.Organisations,
  },
  organisationsDashboard: {
    component: withLazy(
      dImport,
      'Organisations'
    ),
    path: Paths.OrganisationsDashboard,
  },
  organisationsManage: {
    component: withLazy(
      dImport,
      'Organisations'
    ),
    path: Paths.OrganisationsManage,
  },
  organisationsReports: {
    component: withLazy(
      dImport,
      'Organisations'
    ),
    path: Paths.OrganisationsReports,
  },
  organisationsVenues: {
    component: withLazy(
      dImport,
      'Organisations'
    ),
    path: Paths.OrganisationsVenues,
  },
  organisationsSurveys: {
    component: withLazy(
      dImport,
      'Organisations'
    ),
    path: Paths.OrganisationsSurveys,
  },
  organisationsForms: {
    component: withLazy(
      dImport,
      'Organisations'
    ),
    path: Paths.OrganisationsForms,
  },
  organisationsPlanBudget: {
    component: withLazy(
      dImport,
      'Organisations'
    ),
    path: Paths.OrganisationsPlanBudget,
  },
};
