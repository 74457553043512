import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors,
} from 'ui/common/constants';

import {
  Container,
} from 'ui/common/components/Containers';

import {
  ActionHeader
} from 'ui/common/components/table/ActionHeader';

import {
  Header,
} from 'ui/common/components/table/Header';

import {
  Row,
} from 'ui/common/components/table/Row';

import {
  Footer,
} from 'ui/common/components/table/Footer';

const TableContainer = styled(Container)`
  flex-direction: column;
`;

export const Table = props => {

  const [
    state,
    setState,
  ] = React.useState({
    tuples: [...props.data],
    columns: props.columns,
  });

  let filterBouncing = undefined;

  const createRows = tuples => {

    if (!Array.isArray(tuples)) {
      return [];
    }

    let rows = [];
    let i = 0;

    for (const tuple of tuples) {

      if (!tuple) {
        continue;
      }

      rows.push(

        <Row
          key={`row-${tuple.id || i++}`}
          backgroundColor={(i % 2 && Colors.Grey) || undefined}
          readonly={props.readonly}
          actions={props.actions}
          columns={state.columns}
          tuple={tuple}
          onSelect={onRowChange}/>
      );
    }

    return rows;
  };

  const onColumnChange = column => {

    if (!column) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      columns: {
        ...prevState.columns,
        ...column,
      }
    }));
  };

  const onRowChange = tuple => {

    /*if (!tuple) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      tuple: {
        ...prevState.columns,
        ...column,
      }
    }));*/
  };

  const onFilter = filter => {

    if (typeof props.onFilter !== 'function') {
      return;
    }

    clearTimeout(filterBouncing);

    filterBouncing = setTimeout(
      () => props.onFilter(filter),
      props.filterEasing,
    );
  };

  const onSelectAll = () => {

  }

  const rows = createRows(state.tuples);

  return (

    <TableContainer>

      { !props.readonly &&

        <ActionHeader
          actions={props.actions}
          columns={state.columns}
          onColumnChange={onColumnChange}
          onFilter={(typeof props.onFilter === 'function' && onFilter) || undefined}
          onExport={props.onExport}
          onAction={props.onAction}/>
      }

      { !!props.columns &&

        <Header
          addAction={!!props.actions}
          readonly={props.readonly}
          columns={state.columns}
          onColumnChange={onColumnChange}
          onSelectAll={onSelectAll}/>
      }

      {rows}

      { !!props.pages &&

        <Footer
          items={props.items}
          item={props.item}
          pages={props.pages}
          page={props.page}
          onItemsChange={props.onItemChange}
          onPageChange={props.onPageChange}/>
      }

    </TableContainer>
  );
};

Table.displayName = 'Table';

Table.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  readonly: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  columns: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.number,
  page: PropTypes.number,
  items:PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  filterEasing: PropTypes.number,
  onAction: PropTypes.func,
  onFilter: PropTypes.func,
  onExport: PropTypes.func,
  onItemChange: PropTypes.func,
  onPageChange: PropTypes.func,
};

Table.defaultProps = {
  height: 'auto',
  width: 'auto',
  readonly: false,
  actions: undefined,
  columns: undefined,
  data: undefined,
  pages: undefined,
  page: undefined,
  items: [{
    name: '10'
  }, {
    name: '25'
  }, {
    name: '50'
  }, {
    name: '100'
  }],
  item: {
    name: '10',
  },
  filterEasing: 500,
  onAction: undefined,
  onFilter: undefined,
  onExport: undefined,
  onItemChange: undefined,
  onPageChange: undefined,
};
