import
  PropTypes
from 'prop-types';

import
  styled
from 'styled-components/macro';

import {
  Card
} from 'ui/common/components';

export const ContentCard = styled(Card)`
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  height: ${props => props.height};
  width: ${props => props.width};
`;

ContentCard.displayName = 'ContentCard';

ContentCard.propTypes = {
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

ContentCard.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0.25rem 0',
  padding: '0.75rem',
  height: 'auto',
  width: 'auto',
};
