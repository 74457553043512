export const Paths = {

  ConfirmPassword: '/identity/confirm-password',
  Login: '/identity/login',
  Forgot: '/identity/forgot',
  NewPassword: '/identity/new-password',
  NewUsername: '/identity/new-username',
  Landing: '/landing',

  Insights: '/insights/home',

  Members: '/members/home',
  MembersManage: '/members/manage',
  MembersProfile: '/members/profile/:memberId/:section',
  MembersTransfer: '/members/transfer',
  MembersAddNewMember: '/members/add-new-member',
  MembersReports: '/members/reports',
  MembersPivotReports: '/members/pivot-reports',

  Organisations: '/organisations/home',
  OrganisationsDashboard: '/organisations/dashboard',
  OrganisationsManage: '/organisations/manage',
  OrganisationsReports: '/organisations/reports',
  OrganisationsVenues: '/organisations/venues',
  OrganisationsSurveys: '/organisations/surveys',
  OrganisationsForms: '/organisations/forms',
  OrganisationsPlanBudget: '/organisations/plan-budget',

  Tournaments: '/tournaments/home',
  TournamentsManage: '/tournaments/manage',
  TournamentsNewTournament: '/tournaments/new-tournamemt',
  TournamentsManageFixtures: '/tournaments/manage-fixtures',
  TournamentsInsightReports: '/tournaments/insight-reports',
  TournamentsPivotReports: '/tournaments/pivot-reports',

  Squads: '/squads/home',
  SquadsManage: '/squads/manage',
  SquadsNewSquad: '/squads/new-squad',
  SquadsInsightReports: '/squads/insight-reports',
  SquadsPivotReports: '/squads/pivot-reports',

  Medical: '/medical/home',

  Settings: '/settings/home',
  SettingsCountries: '/settings/countries',
  SettingsVenues: '/settings/venues',
  SettingsGroupsRoles: '/settings/groups-roles',
  SettingsOrganisationStructures: '/settings/organisation-structures',
  SettingsSportCodes: '/settings/sport-codes',
  SettingsQualifications: '/settings/qualifications',
  SettingsAchievements: '/settings/achievements',
  SettingsLibraryToolbox: '/settings/library-toolbox',
  SettingsSurveys: '/settings/surveys',
  SettingsNotifications: '/settings/notifications',
  SettingsEmailTemplates: '/settings/email-templates',
  SettingsSupport: '/settings/support',
};

export const Colors = {
  Transparent: '#00000000',
  BlackTransparent10: '#23222310',
  DarkBlack: '#1b191b',
  Black: '#232223',
  DarkerGrey: '#424242',
  Grey: '#595959',
  LightGrey: '#303030',
  White: '#d0d0d0',
  LighterBlue: '#196aba',
  BlueTransparent30: '#0056b330',
  Blue: '#0056b3',
  Red: '#dc3545',
  Green: '#27d115',
  Yellow: '#ffc107',
  LightGreyTransparent26: '#D6D6D626',
  BlueCharcoal: '#212529',
};

export const Shadows = {
  NoOffsetSlightGradientTransparentBlack: `0 1px 15px ${Colors.BlackTransparent10}, 0 1px 8px ${Colors.BlackTransparent10}`,
  BasicShadow: `0 1px 15px rgba(0,0,0,.1), 0 1px 8px rgba(0,0,0,.1)`
};

export const Images = {
  Backgrounds: {
    Blue: '/assets/images/backgrounds/blue.jpg',
    Black: '/assets/images/backgrounds/black.jpg',
  },
  Logos: {
    Footprint: '/assets/images/logos/footprint.svg',
    FootprintType: '/assets/images/logos/footprint-type.svg',
  },
  Icons: {
    Avatar: '/assets/images/icons/avatar.svg',
    MenuIconSmall: '/assets/images/icons/menu_icon_small.svg',
    MenuIconLarge: '/assets/images/icons/menu_icon_large.svg',
  }
};

export const Order = {
  Default: 'default',
  Ascending: 'asc',
  Descending: 'desc',
};

export const Icons = {
  User: '\ue005',
  People: '\ue001',
  UserFemale: '\ue000',
  UserFollow: '\ue002',
  UserFollowing: '\ue003',
  UserUnfollow: '\ue004',
  Login: '\ue066',
  Logout: '\ue065',
  EmoteSmile: '\ue021',
  Phone: '\ue600',
  CallEnd: '\ue048',
  CallIn: '\ue047',
  CallOut: '\ue046',
  Map: '\ue033',
  LocationPin: '\ue096',
  Direction: '\ue042',
  Directions: '\ue041',
  Compass: '\ue045',
  Layers: '\ue034',
  Menu: '\ue601',
  List: '\ue067',
  OptionsVertical: '\ue602',
  Options: '\ue603',
  ArrowDown: '\ue604',
  ArrowLeft: '\ue605',
  ArrowRight: '\ue606',
  ArrowUp: '\ue607',
  ArrowUpCircle: '\ue078',
  ArrowLeftCircle: '\ue07a',
  ArrowRightCircle: '\ue079',
  ArrowDownCircle: '\ue07b',
  Check: '\ue080',
  Clock: '\ue081',
  Plus: '\ue095',
  Minus: '\ue615',
  Close: '\ue082',
  Event: '\ue619',
  Exclamation: '\ue617',
  Organization: '\ue616',
  Trophy: '\ue006',
  ScreenSmartphone: '\ue010',
  ScreenDesktop: '\ue011',
  Plane: '\ue012',
  Notebook: '\ue013',
  Mustache: '\ue014',
  Mouse: '\ue015',
  Magnet: '\ue016',
  Energy: '\ue020',
  Disc: '\ue022',
  Cursor: '\ue06e',
  CursorMove: '\ue023',
  Crop: '\ue024',
  Chemistry: '\ue026',
  Speedometer: '\ue007',
  Shield: '\ue00e',
  ScreenTablet: '\ue00f',
  MagicWand: '\ue017',
  Hourglass: '\ue018',
  Graduation: '\ue019',
  Ghost: '\ue01a',
  GameController: '\ue01b',
  Fire: '\ue01c',
  Eyeglass: '\ue01d',
  EnvelopeOpen: '\ue01e',
  EnvelopeLetter: '\ue01f',
  Bell: '\ue027',
  Badge: '\ue028',
  Anchor: '\ue029',
  Wallet: '\ue02a',
  Vector: '\ue02b',
  Speech: '\ue02c',
  Puzzle: '\ue02d',
  Printer: '\ue02e',
  Present: '\ue02f',
  Playlist: '\ue030',
  Pin: '\ue031',
  Picture: '\ue032',
  Handbag: '\ue035',
  GlobeAlt: '\ue036',
  Globe: '\ue037',
  FolderAlt: '\ue039',
  Folder: '\ue089',
  Film: '\ue03a',
  Feed: '\ue03b',
  Drop: '\ue03e',
  Drawer: '\ue03f',
  Docs: '\ue040',
  Doc: '\ue085',
  Diamond: '\ue043',
  Cup: '\ue044',
  Calculator: '\ue049',
  Bubbles: '\ue04a',
  Briefcase: '\ue04b',
  BookOpen: '\ue04c',
  BasketLoaded: '\ue04d',
  Basket: '\ue04e',
  Bag: '\ue04f',
  ActionUndo: '\ue050',
  ActionRedo: '\ue051',
  Wrench: '\ue052',
  Umbrella: '\ue053',
  Trash: '\ue054',
  Tag: '\ue055',
  Support: '\ue056',
  Frame: '\ue038',
  SizeFullscreen: '\ue057',
  SizeActual: '\ue058',
  Shuffle: '\ue059',
  ShareAlt: '\ue05a',
  Share: '\ue05b',
  Rocket: '\ue05c',
  Question: '\ue05d',
  PieChart: '\ue05e',
  Pencil: '\ue05f',
  Note: '\ue060',
  Loop: '\ue064',
  Home: '\ue069',
  Grid: '\ue06a',
  Graph: '\ue06b',
  Microphone: '\ue063',
  MusicToneAlt: '\ue061',
  MusicTone: '\ue062',
  EarphonesAlt: '\ue03c',
  Earphones: '\ue03d',
  Equalizer: '\ue06c',
  Like: '\ue068',
  Dislike: '\ue06d',
  ControlStart: '\ue06f',
  ControlRewind: '\ue070',
  ControlPlay: '\ue071',
  ControlPause: '\ue072',
  ControlForward: '\ue073',
  ControlEnd: '\ue074',
  Volume1: '\ue09f',
  Volume2: '\ue0a0',
  VolumeOff: '\ue0a1',
  Calendar: '\ue075',
  Bulb: '\ue076',
  Chart: '\ue077',
  Ban: '\ue07c',
  Bubble: '\ue07d',
  Camrecorder: '\ue07e',
  Camera: '\ue07f',
  CloudDownload: '\ue083',
  CloudUpload: '\ue084',
  Envelope: '\ue086',
  Eye: '\ue087',
  Flag: '\ue088',
  Heart: '\ue08a',
  Info: '\ue08b',
  Key: '\ue08c',
  Link: '\ue08d',
  Lock: '\ue08e',
  LockOpen: '\ue08f',
  Magnifier: '\ue090',
  MagnifierAdd: '\ue091',
  MagnifierRemove: '\ue092',
  PaperClip: '\ue093',
  PaperPlane: '\ue094',
  Power: '\ue097',
  Refresh: '\ue098',
  Reload: '\ue099',
  Settings: '\ue09a',
  Star: '\ue09b',
  SymbolFemale: '\ue09c',
  SymbolMale: '\ue09d',
  Target: '\ue09e',
  CreditCard: '\ue025',
  PayPal: '\ue608',
  SocialTumblr: '\ue00a',
  SocialTwitter: '\ue009',
  SocialFacebook: '\ue00b',
  SocialInstagram: '\ue609',
  SocialLinkedin: '\ue60a',
  SocialPinterest: '\ue60b',
  SocialGithub: '\ue60c',
  SocialGoogle: '\ue60d',
  SocialReddit: '\ue60e',
  SocialSkype: '\ue60f',
  SocialDribble: '\ue00d',
  SocialBehance: '\ue610',
  SocialFoursquare: '\ue611',
  SocialSoundCloud: '\ue612',
  SocialSpotify: '\ue613',
  SocialStumbleUpon: '\ue614',
  SocialYoutube: '\ue008',
  SocialDropbox: '\ue00c',
  SocialVkontakte: '\ue618',
  SocialSteam: '\ue620'
}
