import
  React
from 'react';

import
  styled, {
    keyframes,
} from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors
} from 'ui/common/constants';

import {
  VFillContainerVH,
} from 'ui/common/components/Containers';

const SpinFrames = keyframes`

  to {
    transform: rotate(360deg);
  }
`;

const Container = styled(VFillContainerVH)`
  padding: ${props => props.padding};
`;

const SpinContainer = styled.div`
  width: 2.5vmin;
  height: 2.5vmin;
  border: 0.3vmin solid ${Colors.BlueTransparent30};
  border-radius: 50%;
  border-top-color: ${Colors.LighterBlue};
  animation: ${SpinFrames} 1s linear infinite;
  left: calc(50% - 1.25vmin);
  top: calc(50% - 1.25vmin);
`;

export const Spinner = props =>

  <Container
    padding={props.padding}>

    <SpinContainer/>
  </Container>
;

Spinner.displayName = 'Spinner';

Spinner.propTypes = {
  padding: PropTypes.string,
}

Spinner.defaultProps = {
  padding: '2rem',
};
