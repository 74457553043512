import
  React
from 'react';

import {
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import {
  Navigator,
  Routes,
  NavigatorType,
} from 'ui/navigation';

import {
  Toast,
  useShowErrorToast,
} from 'ui/common';

import {
  IdentityAtom,
  EnumeratorAtom,
} from 'ui/atoms';

import {
  EnumeratorApi
} from 'api';

const App = () => {

  const identity = useRecoilValue(IdentityAtom);
  const setEnumerator = useSetRecoilState(EnumeratorAtom);
  const showErrorToast = useShowErrorToast('Initialisation');

  React.useEffect(() => {

    if (!identity.user) {
      return;
    }

    const enumerate = async () => {

      let countries = (await EnumeratorApi.get('countries')).countries;
      let gender = (await EnumeratorApi.get('gender')).gender;
      let status = (await EnumeratorApi.get('status')).status;

      setEnumerator(prevEnum => ({
        ...prevEnum,
        gender,
        countries,
        status
      }));
    };

    enumerate()
      .catch(e => showErrorToast(e));

  }, [
    identity.user,
    setEnumerator,
    showErrorToast,
  ]);

  return (

    <>
      <Navigator
        id={'app-navigator'}
        base={'/'}
        routes={Routes}
        type={NavigatorType.Browser}/>

      <Toast/>
    </>
  );
};

App.displayName = 'App';

export default App;
