import {
  Paths,
  withLazy
} from 'ui/common';

const dImport = import('ui/settings');

export const SettingsRoutes = {
  settings: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.Settings,
  },
  settingsCountries: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsCountries,
  },
  settingsVenues: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsVenues,
  },
  settingsGroupRoles: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsGroupsRoles,
  },
  settingsOrganisationStructures: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsOrganisationStructures,
  },
  settingsSportCodes: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsSportCodes,
  },
  settingsQualifications: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsQualifications,
  },
  settingsAchievements: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsAchievements,
  },
  settingsLibraryToolbox: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsLibraryToolbox,
  },
  settingsSurveys: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsSurveys,
  },
  settingsNotifications: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsNotifications,
  },
  settingsEmailTemplates: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsEmailTemplates,
  },
  settingsSupport: {
    component: withLazy(
      dImport,
      'Settings'
    ),
    path: Paths.SettingsSupport,
  },
};
