import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Icons,
} from 'ui/common/constants';

import {
  Container,
} from 'ui/common/components/Containers';

import {
  Icon,
} from 'ui/common/components/Icon';

import {
  Input
} from 'ui/common/components/Input';

const SearchContainer = styled(Container)`
  position: relative;
  align-items: center;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 0.9rem;
`;

export const SearchBox = props => {

  const onChange = e => {

    if (!e || !e.target || e.defaultPrevented) {
      return;
    }

    typeof props.onChange === 'function' && props.onChange(e.target.value);
  };

  return (

    <SearchContainer
      id={props.id}
      name={props.name}
      height={props.height}
      width={props.width}>

      <Input
        id={props.id}
        name={props.name}
        margin={'0'}
        minWidth={'0'}
        width={'100%'}
        borderRadius={'50vmin'}
        placeholder={'Filter'}
        value={props.value}
        onChange={onChange}/>

      <IconContainer>

        <Icon
          size={'100%'}
          icon={Icons.Magnifier}/>

      </IconContainer>

    </SearchContainer>
  );
};

SearchBox.displayName = 'SearchBox';

SearchBox.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

SearchBox.defaultProps = {
  height: 'auto',
  width: '10rem',
  value: '',
  onChange: undefined,
};
