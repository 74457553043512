import {
  FetchApi
} from 'api/fetch';

export const EnumeratorApi = {

  get: async list => {
    return FetchApi.send(
      'enumerators', {
        method: 'GET',
        query: {
          list,
        }
      },
    );
  },
}
