import {
  Paths
} from 'ui/common';

import {
  ConfirmPassword,
  Forgot,
  Login,
  NewPassword,
  NewUsername,
} from 'ui/identity';

export const IdentityRoutes = {
  confirmPassword: {
    component: ConfirmPassword,
    path: Paths.ConfirmPassword,
    excludeAuth: true,
  },
  login: {
    component: Login,
    path: Paths.Login,
    default: true,
    excludeAuth: true,
  },
  forgot: {
    component: Forgot,
    path: Paths.Forgot,
    excludeAuth: true,
  },
  newPassword: {
    component: NewPassword,
    path: Paths.NewPassword,
    excludeAuth: true,
  },
  newUsername: {
    component: NewUsername,
    path: Paths.NewUsername,
  },
};
