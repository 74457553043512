import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import
  PropTypes
from 'prop-types';

import {
  Colors,
} from 'ui/common/constants';

import {
  HContainerV,
} from 'ui/common/components/Containers';

import {
  DropDown
} from 'ui/common/components/DropDown';

import {
  Text
} from 'ui/common/components/Type';

import {
  Pager
} from 'ui/common/components/table/Pager';

const Container = styled(HContainerV)`
  justify-content: space-between;
  padding: 0.75rem;
`;

const SideContainer = styled(HContainerV)`
  width: 8rem;
  justify-content: space-evenly;
`;

export const Footer = props => {

  const createPageValues = pages => {

    if (!pages) {
      return [];
    }

    let pageValues = [];

    for (let i = 0; i < pages;) {

      ++i;

      pageValues.push({
        name: `${i}`,
      });
    }

    return pageValues;
  };

  const pageValues = createPageValues(props.pages);

  return (

    <Container>

      { !!props.pages &&

        <>
          <SideContainer>

            <Text
              color={Colors.Grey}>

              Page
            </Text>

            <DropDown
              id={'table-footer-pages'}
              padding={'0.3rem'}
              height={'0.6rem'}
              width={'1.6rem'}
              fontSize={'0.6rem'}
              backgroundColor={Colors.DarkBlack}
              backgroundColorHover={Colors.Grey}
              border={`1px solid ${Colors.Grey}`}
              value={{name: `${props.page}`}}
              values={pageValues}
              placeholder={'PAGES'}
              onSelect={props.onPageChange}/>

            <Text
              color={Colors.Grey}>

              {`of ${props.pages}`}
            </Text>

          </SideContainer>

          <Pager
            pages={props.pages}
            page={props.page}/>

          <SideContainer>

            <Text
              color={Colors.Grey}>

              Items
            </Text>

            <DropDown
              id={'table-footer-pages'}
              padding={'0.3rem'}
              height={'0.6rem'}
              fontSize={'0.6rem'}
              backgroundColor={Colors.DarkBlack}
              backgroundColorHover={Colors.Grey}
              border={`1px solid ${Colors.Grey}`}
              value={props.item}
              values={props.items}
              placeholder={'ITEMS'}
              onSelect={props.onItemsChange}/>

          </SideContainer>
        </>
      }

    </Container>
  );
};

Footer.displayName = 'Footer';

Footer.propTypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
};

Footer.defaultProps = {
  pages: undefined,
  page: undefined,
};
