import {
  Paths,
  withLazy,
} from 'ui/common';

const dImport = import('ui/members');

export const MembersRoutes = {
  members: {
    component: withLazy (
      dImport,
      'Members'
    ),
    path: Paths.Members,
  },
  membersManage: {
    component: withLazy(
      dImport,
      'Members'
    ),
    path: Paths.MembersManage
  },
  membersProfile: {
    component: withLazy(
      dImport,
      'Members'
    ),
    path: Paths.MembersProfile
  },
  membersTransfer: {
    component: withLazy(
      dImport,
      'Members'
    ),
    path: Paths.MembersTransfer
  },
  membersAddNewMember: {
    component: withLazy(
      dImport,
      'AddNewMember'
    ),
    path: Paths.MembersAddNewMember
  },
  membersReports: {
    component: withLazy(
      dImport,
      'Members'
    ),
    path: Paths.MembersReports
  },
  membersPivotReports: {
    component: withLazy(
      dImport,
      'Members'
    ),
    path: Paths.MembersPivotReports
  },
};
