import {
  Paths,
  withLazy,
} from 'ui/common';

const dImport = import('ui/squads');

export const SquadsRoutes = {
  squads: {
    component: withLazy(
      dImport,
      'Squads'
    ),
    path: Paths.Squads,
  },
  squadsManage: {
    component: withLazy(
      dImport,
      'Squads'
    ),
    path: Paths.SquadsManage,
  },
  squadsNewSquad: {
    component: withLazy(
      dImport,
      'Squads'
    ),
    path: Paths.SquadsNewSquad,
  },
  squadsInsightReports: {
    component: withLazy(
      dImport,
      'Squads'
    ),
    path: Paths.SquadsInsightReports,
  },
  squadsPivotReports: {
    component: withLazy(
      dImport,
      'Squads'
    ),
    path: Paths.SquadsPivotReports,
  },
};
