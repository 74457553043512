export const createFormDefinitionFromValues = (
  data,
  definition,
  enumerators,
) => {

  if (!definition) {
    return;
  }

  let ret = {};

  for (const [key, field] of Object.entries(definition)) {

    const lookupValues = (enumerators && enumerators[field.lookupName]) || field.lookupValues || [];
    let value = data && data.hasOwnProperty(key) ? data[key] : field.value;

    if (lookupValues.length) {

      // Assume mapping enumerator id to an id field in the data
      value = lookupValues.find(x => `${x.id}` === `${value}`);
    }

    ret[key] = {
      ...field,
      value: value,
      lookupValues,
    };
  }

  return ret;
};

export const getModelFromFormDefinition = definition => {

  if (!definition) {
    return {};
  }

  const ret = {};

  for (const [key, value] of Object.entries(definition)) {

    ret[key] = value.value && value.value.hasOwnProperty('id')
      ? value.value.id
      : value.value;
  }

  return ret;
}

export const checkNested = (obj, key) => {

  return key.split(".").reduce(function (o, x) {
    return (typeof o == "undefined" || o === null) ? o : o[x];
  }, obj);

};
