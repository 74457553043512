export const Validation = {

  isValidEmail: email => /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]+)])/.test(email),
  isValidUserName: user => typeof user === 'string' && user.length > 3,
  isValidPassword: password => typeof password === 'string' && password.length > 3,
  isValidMobileNumber: mobile => /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(mobile),
  isValidDate: date => /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/.test(date),
  isMatch: (textOne, textTwo) => textOne.toLowerCase() === textTwo.toLowerCase(),
  isValidVerificationCode: code => code.length >= 6,
};
